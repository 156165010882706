import securedApi from "./securedApi";
import { ApiUrls } from "../../../assets/js/ApiUrls";
import { ApiSuccess } from "../../../../types";
import { AdsAccountsData, Invoice, NewPasswordParams, OnboardingAPI, ProfileType, User } from "../../types/profile";
import { Company } from "../../types/campaign";

const domain = process.env.REACT_APP_APP_DOMAIN !== "http://localhost:3000" ? process.env.REACT_APP_APP_DOMAIN : "https://app.feedcast.io"
export const oauthRedirect = domain + '/oauth/callback';

const profileApi = securedApi.injectEndpoints({
  endpoints: build => ({
    logout: build.mutation<void, void>({
      query: () => ({
        url: ApiUrls.auth.logout,
        method: "POST",
      })
    }),
    getMe: build.query<User, void>({
      query: () => ApiUrls.user.me,
    }),
    getProfile: build.query<ProfileType, void>({
      query: () => ApiUrls.user.profile,
      providesTags: [ "Profile" ],
    }),
    setProfile: build.mutation<ProfileType, Partial<ProfileType>>({
      query: (body) => ({
        url: ApiUrls.user.profile,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Profile" ],
    }),
    setOnboarding: build.mutation<ApiSuccess, OnboardingAPI>({
      query: (body) => ({
        url: ApiUrls.user.onboarding,
        method: 'POST',
        body
      })
    }),
    setNewPassword: build.mutation<ApiSuccess, NewPasswordParams>({
      query: (body) => ({
        url: ApiUrls.user.newPassword,
        method: 'POST',
        body,
      })
    }),
    getGoogleAccounts: build.query<AdsAccountsData, OauthInit>({
      query: (params) => ({
        url: ApiUrls.oauth.google,
        params,
      }),
    }),
    setGoogleLink: build.mutation<ApiSuccess, OauthCallback>({
      query: (body) => ({
        url: ApiUrls.oauth.google,
        method: 'POST',
        params: { redirect_url: body.redirect_url },
        body,
      })
    }),
    getMetaAccounts: build.query<AdsAccountsData, OauthInit>({
      query: (params) => ({
        url: ApiUrls.oauth.meta,
        params,
      })
    }),
    setMetaLink: build.mutation<ApiSuccess, OauthCallback>({
      query: (body) => ({
        url: ApiUrls.oauth.meta,
        method: 'POST',
        params: { redirect_url: body.redirect_url },
        body,
      })
    }),
    getMicrosoftAccounts: build.query<AdsAccountsData, OauthInit>({
      query: (params) => ({
        url: ApiUrls.oauth.microsoft,
        params,
      }),
    }),
    setMicrosoftOauth: build.mutation <ApiSuccess, OauthCallback>({
      query: (body) => ({
        url: ApiUrls.oauth.microsoft,
        method: 'POST',
        params: { redirect_url: body.redirect_url },
        body,
      })
    }),
    getInvoices: build.query<Invoice[], void>({
      query: () => ApiUrls.user.invoices,
    }),
    getCredits: build.query<{ limit: null | number }, { feed: string | number }>({
      query: ({ feed }) => ApiUrls.ai.credits(feed),
      providesTags: [ "AI_Credits" ],
    })
  })
});

export default profileApi;

/********************************************************* Utility */
export const saveOauth = (feed: number, company: Company) => {
  localStorage.setItem("oauth_feed", feed.toString());
  localStorage.setItem("oauth_url", window.location.pathname);
  localStorage.setItem("oauth_company", company);
}

export const getOauth = (): { feed: number, url: string | null, company: Company } => {
  return {
    feed: parseInt(localStorage.getItem("oauth_feed") as string),
    url: localStorage.getItem("oauth_url"),
    company: localStorage.getItem("oauth_company") as Company
  }
}

export const clearOauth = () => {
  localStorage.removeItem("oauth_feed");
  localStorage.removeItem("oauth_url");
  localStorage.removeItem("oauth_company");
}


/** Types */
type OauthInit = { redirect_url: string };
type OauthCallback = { code: string } & OauthInit;
