import AppPublic from "../AppPublic";
import { FormattedMessage } from "react-intl";
import SimpleForm from "../../Blocks/SimpleForm";
import { LoadingButton } from "../../Blocks/Misc";
import Urls from "../../../../assets/js/Urls";
import authApi, { LoginPayload } from "../../../../app/services/public/auth";
import { StorageManager } from "../../../../app/storage";
import { Link as LinkDOM, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { Box, FormControl, InputAdornment, Link, TextField, Typography } from "@mui/material";
import { AccountCircle, LockOpen, LoginRounded } from "@mui/icons-material";
import useJune from "../../../../app/hooks/useJune";
import { useAppSelector } from "../../../../app/hooks";
import { getAppMode } from "../../../../app/slices/app";

const Login = () => {
  const isLogged = (StorageManager.get()?.length || 0) > 0;
  return (
    <AppPublic page={"login"}>
      <LoginForm/>
      {isLogged && <Navigate to={Urls.auth.roundabout}/>}
    </AppPublic>
  )
}

const LoginForm = () => {

  useJune();

  const [ log, logRes ] = authApi.useLoginMutation();
  const appMode = useAppSelector(getAppMode);

  useEffect(() => {
    if (logRes.isSuccess) {
      StorageManager.set(logRes.data.access_token);
      window.location.href = process.env.REACT_APP_APP_DOMAIN + Urls.auth.roundabout;
    }
  }, [ logRes ]);

  return (
    <SimpleForm onSubmit={s => log(s as LoginPayload)}>

      <FormControl fullWidth>
        <TextField
          name={'email'}
          label={'Email'}
          size={"small"}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <AccountCircle/>
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      <FormControl margin={"normal"} fullWidth>
        <TextField
          label={<FormattedMessage id={"password"}/>}
          name={"password"}
          type={"password"}
          size={"small"}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <LockOpen/>
              </InputAdornment>
            )
          }}
        />
      </FormControl>

      <LoadingButton
        type={"submit"}
        reset
        data={logRes}
        endIcon={<LoginRounded/>}
        size={"large"}
        variant={"contained"}
        color={appMode === "dark" ? "secondary" : undefined}
        sx={{
          width: '100%',
          mt: 1,
        }}
      >
        <FormattedMessage id={'continue'}/>
      </LoadingButton>
    </SimpleForm>
  )
}

export default Login;
