import useAnchorEl from "../../../../../app/hooks/useAnchorEl";
import { useAppSelector } from "../../../../../app/hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress, FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { AutoAwesome, Refresh } from "@mui/icons-material";
import { AiFieldProps } from "./TitleDesc";
import { motion } from "framer-motion";
import { getCurrentProductAi } from "../../../../../app/slices/product";

export const Description = ({ value, disabled, generateAiText, onChange, productAiRes }: AiFieldProps) => {

  const { open, handleClick, handleClose, anchorEl } = useAnchorEl();

  const descriptionVersions = useAppSelector(getCurrentProductAi("description"));

  const [ genLoading, setGenLoading ] = useState(false);
  const versionsArrayMemo = useMemo(() => descriptionVersions?.versions ?? [], [ descriptionVersions?.versions ]);
  const [ versionTracking, setVersionTracking ] = useState<string[]>([]);

  const hasVersions = versionsArrayMemo.length > 0;
  const lastGeneratedValue = useMemo(() => versionsArrayMemo[versionsArrayMemo.length - 1]?.content, [ versionsArrayMemo ]);
  const originalValue = useMemo(() => descriptionVersions?.original, [ descriptionVersions?.original ]);
  const isOriginalBeingDisplayed = useMemo(() => value === originalValue, [ value, originalValue ]);

  // useEffect(() => {
  //   if (value) {
  //     onChange(value);
  //   }
  // }, [ value ]);

  useEffect(() => {
    if (versionsArrayMemo.length > versionTracking.length) {
      const vTrack = [ ...versionTracking ];
      versionsArrayMemo.forEach(version => {
        if (!versionTracking.includes(version.id)) {
          vTrack.push(version.id);
        }
      });
      setVersionTracking(vTrack);
      onChange(versionsArrayMemo[versionsArrayMemo.length - 1].content);
    }
    setGenLoading(false);
  }, [ versionsArrayMemo ]);


  const triggerGeneration = () => {
    setGenLoading(true);
    generateAiText("description");
    handleClose();
  }

  const toggleVersion = useCallback(() => {
    if (lastGeneratedValue && originalValue) {
      const newVal = isOriginalBeingDisplayed ? lastGeneratedValue : originalValue;
      onChange(newVal);
    } else {
      triggerGeneration();
    }
  }, [ isOriginalBeingDisplayed, lastGeneratedValue, originalValue ]);

  const editField = (val: string) => {
    onChange(val);
  }

  useEffect(() => {
    if (productAiRes.isError) {
      setGenLoading(false);
    }
  }, [productAiRes]);

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <TextField
          multiline
          rows={16}
          label={<FormattedMessage id={"description"}/>}
          value={value}
          onChange={(e) => editField(e.target.value)}
          disabled={disabled || genLoading}
          sx={{ position: "relative" }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position={"end"}
                sx={{ position: "absolute", bottom: "12%", right: '0', transform: "translate(-50%)", }}>
                <motion.div
                  initial={{
                    opacity: 0.7,
                    transform: "scale(0.9)",
                  }}
                  whileHover={{
                    opacity: 1,
                    transform: "scale(1)",
                  }}
                >
                  <IconButton
                    disabled={genLoading}
                    onClick={toggleVersion}
                    sx={{
                      // color: "ai.main",
                      background: (theme) => theme.palette.gradient.secondary,
                    }}
                  >
                    {genLoading ? (
                        <CircularProgress size={"1.3rem"} sx={{color: "#fff"}}/>
                      ) :
                      (!hasVersions || isOriginalBeingDisplayed) ? (
                        <AutoAwesome/>
                      ) : (
                        <Refresh/>
                      )}
                  </IconButton>
                </motion.div>
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </React.Fragment>
  )
}
