import {getCustomHeaders} from "../../../assets/js/ApiUrls";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";


const publicApi = createApi({
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => getCustomHeaders(headers, false),
        baseUrl: process.env.REACT_APP_API_DOMAIN
    }),
    endpoints: () => ({})
});

export default publicApi;