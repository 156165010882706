import { CampaignTypeIdentifier } from "../../app/types/campaign";

const Urls = {
  auth: {
    login: '/',
    register: '/register',
    validate: '/validate',
    validateToken: (token: string) => `/validate-token/${token}.html`,
    autologin: '/autologin',
    refresh: '/refresh',
    refreshPassword: (token: string) => `/refresh-password/${token}.html`,
    registerReseller: (code:string) => `/register/${code}.html`,
    roundabout: `/roundabout`,
  },
  settings: {
    me: '/settings',
    security: '/settings/security',
    subList: `/settings/subscriptions`,
    subEdit: (feed: string | number) => `/settings/subscriptions/${feed}`,
    history: '/settings/history',
    accounts: '/settings/accounts',
  },
  oauth: '/oauth/callback',
  projects: {
    list: '/projects',
    newFeed: (project: string | number) => `/projects/${project}/new`,
  },
  subscription: {
    callback: (feed: string | number) => `/feed/${feed}/subscription/callback`,
  },
  cgv: '/files/FC_CGV.pdf',

  // new nav

  onboarding: `/onboarding`,
  dash: (feed: string | number) => `/feed/${feed}/dash`,
  configuration: (feed: string|number) => `/feed/${feed}/configuration`,

  products: {
    catalog: (feed: string|number) => `/feed/${feed}/products/catalog`,
    list:  (feed: string | number) => `/feed/${feed}/products/list`,
    edit: (feed: string|number, product: string|number) => `/feed/${feed}/products/${product}`,
    optimisation:  (feed: string | number) => `/feed/${feed}/products/optimisation`,
    customLabels: (feed: string|number) => `/feed/${feed}/products/labels`,
  },

  perf: {
    globalView: (feed: string | number) => `/feed/${feed}/performances/charts`,
    products: (feed: string | number) => `/feed/${feed}/performances/products`,
  },

  campaigns: {
    edit: (feed: string | number, provider: string, campaign: string) => `/feed/${feed}/campaigns/${provider}/${campaign}`,
    new: (feed: string | number, provider: string, type: CampaignTypeIdentifier) => `/feed/${feed}/campaigns/${provider}/new/${type}`,
    list2: (feed: string | number) => `/feed/${feed}/campaigns/list`,
  },
}

export default Urls;
