import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setCurrentFeed } from "./app";
import { RootState } from "../store";
import shopify from "../../assets/img/shopify-full.svg";
import shopify_logo from "../../assets/img/shopify.svg";
import s_1 from "../../assets/img/tutorials/shopify/1.png";
import s_2 from "../../assets/img/tutorials/shopify/2.png";
import s_3 from "../../assets/img/tutorials/shopify/3.png";
import s_4 from "../../assets/img/tutorials/shopify/4.png";
import s_5 from "../../assets/img/tutorials/shopify/5.png";
import woocommerce from "../../assets/img/woocommerce-full.svg";
import wooLogo from "../../assets/img/woocommerce.svg";
import w_1 from "../../assets/img/tutorials/woocommerce/1.png";
import w_2 from "../../assets/img/tutorials/woocommerce/2.png";
import w_3 from "../../assets/img/tutorials/woocommerce/3.png";
import w_4 from "../../assets/img/tutorials/woocommerce/4.png";
import prestaLogo from "../../assets/img/prestashop.svg";
import prestashop from "../../assets/img/prestashop-full.svg";
import webflow from "../../assets/img/webflow-full.svg";
import webflow_video from "../../assets/resources/webflow-setup.mp4";
import wizishop from "../../assets/img/wizishop-full.svg";
import wi_1 from "../../assets/img/tutorials/wizishop/1.png";
import wi_2 from "../../assets/img/tutorials/wizishop/2.png";
import wi_3 from "../../assets/img/tutorials/wizishop/3.png";
import wi_4 from "../../assets/img/tutorials/wizishop/4.png";
import wi_5 from "../../assets/img/tutorials/wizishop/5.png";
import csv from "../../assets/img/csv.svg";
import csvLogo from "../../assets/img/csv-logo.svg";
import sheets from "../../assets/img/sheets-full.svg";
import sheetsLogo from "../../assets/img/sheets.svg";
import app_logo from "../../assets/img/icons/app-logo.svg";
import {
  CompaniesAuth,
  DynamicLabel,
  FeedOptions,
  FeedSourceEnum,
  ShopAccounts,
  ShopPlatforms,
  SourceData,
  SubStatus
} from "../types/feed";
import { AvailableCompany } from "../types/campaign";
import campaignApi from "../services/secured/campaign";
import feedApi from "../services/secured/feed";
import { getFeedHasSource, getFeedSubStatus } from "../utils";
import { Log } from "../types/profile";
import logsApi from "../services/secured/logs";
import { LogType } from "./app_notifications";

export enum OnboardingStepsI {
  ADS,
  FEED,
  FREE,
  CAMPAIGN,
  FINISHED
}

const initialState: ShopSlice = {

  shop: null,
  subscription: null,
  pendingImport: false,
  hasSource: false,
  labels: [],

  products: {
    nb: 0,
    limit: 1000,
  },

  platforms: {
    nb_enabled: 0,
    remaining: 0,
    enabled: {
      google: false,
      meta: false,
      microsoft: false,
    }
  },

  options: {
    enable_free_listing: false,
    export_comparator: false,
  },

  onboarding: {
    activeStep: OnboardingStepsI.ADS,
    complete: false,
  },

  accounts: {
    isOauth: {
      google: null,
      meta: null,
      microsoft: false,
    },
    hasLinkedAccount: {
      google: false,
      meta: false,
      microsoft: false,
    },
    hasTracking: {
      google: false,
      meta: false,
      microsoft: false,
    },
    hasAccess: {
      google: false,
      meta: false,
      microsoft: false,
    },
    hasAlerts: {
      google: false,
      meta: false,
      microsoft: false,
    },
    canCreateCampaigns: {
      google: false,
      meta: false,
      microsoft: false,
    },
    hasCampaigns: {
      google: false,
      meta: false,
      microsoft: false,
      nb: 0,
    },
    hasChannelActive: {
      google: false,
      meta: false,
      microsoft: false,
    },
    hasErrorFetching: {
      google: false,
      meta: false,
      microsoft: false,
    },
    hasPayment: {
      google: false,
      meta: false,
      microsoft: false,
    }
  },
}

const shop = createSlice({
  name: "shop",
  initialState,
  reducers: {
    resetAccounts: (state) => {
      state.accounts = initialState.accounts;
    },
    setCanCreateCampaigns: (state, action: PayloadAction<CompaniesAuth>) => {
      state.accounts.canCreateCampaigns = action.payload;
    },
    setIsOauth: (state, action: PayloadAction<CompaniesAuth>) => {
      state.accounts.isOauth = action.payload;
    },
    setHasLinkedAccount: (state, action: PayloadAction<CompaniesAuth>) => {
      state.accounts.hasLinkedAccount = action.payload;
    },
    setHasCampaigns: (state, action: PayloadAction<CompaniesAuth & { nb: number }>) => {
      state.accounts.hasCampaigns = action.payload;
    },
    setPendingImport: (state, action: PayloadAction<boolean>) => {
      state.pendingImport = action.payload;
    },
    setHasSource: (state, action: PayloadAction<boolean>) => {
      state.hasSource = action.payload;
    },
    setHasChannelActive: (state, action: PayloadAction<CompaniesAuth>) => {
      state.accounts.hasChannelActive = action.payload;
    },
    setOnboardingStep: (state, action: PayloadAction<OnboardingStepsI>) => {
      const step = action.payload;
      state.onboarding.activeStep = step;
      if (step === OnboardingStepsI.FINISHED) {
        state.onboarding.complete = true;
      }
    },
    setHasAccess: (state, action: PayloadAction<CompaniesAuth>) => {
      state.accounts.hasAccess = action.payload;
    },
    setHasTracking: (state, action: PayloadAction<CompaniesAuth>) => {
      state.accounts.hasTracking = action.payload;
    },
    setHasPayment: (state, action: PayloadAction<CompaniesAuth>) => {
      state.accounts.hasPayment = action.payload;
    },
    setHasErrorFetching: (state, action: PayloadAction<CompaniesAuth>) => {
      const { google, meta, microsoft } = action.payload;
      if (google && !state.accounts.hasErrorFetching.google) {
        state.accounts.hasErrorFetching.google = true;
      }
      if (meta && !state.accounts.hasErrorFetching.meta) {
        state.accounts.hasErrorFetching.meta = true;
      }
      if (microsoft && !state.accounts.hasErrorFetching.microsoft) {
        state.accounts.hasErrorFetching.microsoft = true;
      }
    }
  },
  extraReducers: builder => {
    builder
    .addMatcher(setCurrentFeed.match, (state, action) => {

      const f = action.payload;
      const hasSource = getFeedHasSource(f);

      if (state.shop !== f.id) {
        state.accounts = initialState.accounts;
        state.shop = f.id;
        state.hasSource = hasSource;
        state.onboarding = initialState.onboarding; // reset
        state.products.nb = f.last_stat?.imported ?? 0;
        state.products.limit = f.current_subscription?.plan.limit ?? null;
      }

      if (state.pendingImport && f.last_stat?.imported) {
        state.pendingImport = false;
        state.hasSource = hasSource;
      }

      state.subscription = getFeedSubStatus(f);
      state.options = f.feed_option;

      const platforms: Array<AvailableCompany> = [ 'google', 'meta', 'microsoft' ];
      if (f.platforms) {
        state.platforms.nb_enabled = 0;
        platforms.forEach(platform => {
          if (f.platforms) {
            const isEnabled = f.platforms[`${platform}_enabled`];
            state.platforms.enabled[platform] = isEnabled;
            if (isEnabled) {
              state.platforms.nb_enabled += 1;
            }
          }
        });
        state.platforms.remaining = (action.payload.max_platforms ?? MAX_PLATFORMS) - state.platforms.nb_enabled;
      }
    })
    .addMatcher(campaignApi.endpoints.getAccountStatus.matchFulfilled, (state, action) => {
      state.accounts.hasAlerts.google = (action.payload.merchant?.warnings.length || 0) > 0;
    })
    .addMatcher(feedApi.endpoints.getDynamicLabels.matchFulfilled, (state, action) => {
      state.labels = action.payload;
    })

  }
});

export const shopReducer = shop.reducer;

export const {
  setCanCreateCampaigns,
  setIsOauth,
  setHasCampaigns,
  setHasLinkedAccount,
  setPendingImport,
  setHasSource,
  setHasChannelActive,
  setOnboardingStep,
  setHasAccess,
  setHasErrorFetching,
  setHasTracking,
  setHasPayment
} = shop.actions;


//====================
// CONSTANTS
//====================

export const feedSources: Record<FeedSourceEnum | 'fictif', SourceData> = {
  [FeedSourceEnum.Manual]: {
    image: csv,
    logo: csvLogo,
    available: true,
  },
  [FeedSourceEnum.Shopify]: {
    image: shopify,
    logo: shopify_logo,
    available: true,
    tutorial: [ s_1, s_2, s_3, s_4, s_5 ]
  },
  [FeedSourceEnum.Prestashop]: {
    logo: prestaLogo,
    image: prestashop,
    available: true,
    credentials: [ 'store', 'access_token' ],
  },
  [FeedSourceEnum.Woocommerce]: {
    image: woocommerce,
    logo: wooLogo,
    credentials: [ 'store', 'api_key', 'access_token' ],
    available: true,
    tutorial: [ w_1, w_2, w_3, w_4 ],
  },
  [FeedSourceEnum.Webflow]: {
    image: webflow,
    logo: webflow,
    available: true,
    tutorial: [ webflow_video ],
  },
  [FeedSourceEnum.Wizishop]: {
    image: wizishop,
    logo: wizishop,
    available: true,
    tutorial: [ wi_1, wi_2, wi_3, wi_4, wi_5 ]
  },
  [FeedSourceEnum.GoogleSheets]: {
    image: sheets,
    logo: sheetsLogo,
    available: true,
  },
  fictif: {
    image: app_logo,
    logo: app_logo,
    available: true,
  },
};

export const MIN_PLATFORMS = 2;
export const MAX_PLATFORMS = 3;


//====================
// GETTERS
//====================

export const getShop = (state: RootState) => state.shop;
export const getShopAccounts = (state: RootState) => state.shop.accounts;
export const shopHasProducts = (state: RootState) => state.shop.products.nb > 0;

//====================
// TYPES
//====================

export type ShopSlice = {
  shop: number | null,
  pendingImport: boolean,
  hasSource: boolean,
  accounts: ShopAccounts,
  subscription: SubStatus | null,
  platforms: ShopPlatforms,
  labels: DynamicLabel[],
  options: FeedOptions,
  onboarding: {
    activeStep: OnboardingStepsI,
    complete: boolean,
  },
  products: {
    nb: number,
    limit: number | null,
  }
}
