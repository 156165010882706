import { createTheme, ThemeOptions } from "@mui/material";
import { frFR } from "@mui/x-data-grid";

export const makeAppTheme = (lang: "fr" | "en", mode: "light" | "dark"): ThemeOptions => {
  const options: ThemeOptions = {
    typography: {
      fontSize: 11,
      // fontWeightRegular: 300,
      fontFamily: "Roboto",
      button: {
        textTransform: "none" as any,
      }
    },
    palette: {
      mode,
      primary: {
        main: "#967CF9",
      },
      secondary: {
        main: "#2E2D62",
      },
      background: mode === "light"
        ? { default: "#fbfbfb", }
        : { default: "#191919" },
      gradient: {
        main: "linear-gradient(135deg, rgba(82, 79, 240, 0.05), rgba(169, 24, 222, 0.05))",
        secondary: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      },
      ai: {
        main: "#FF8E53",
      },
      tonalOffset: 0.9,
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: mode === "light"
            ? {
              borderRadius: 10,
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            }
            : {
              borderRadius: 10,
              // background: "#202020",
            }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
          }
        }
      }
    },

  };

  if (lang === "fr") {
    return createTheme(options, frFR);
  }

  return createTheme(options);
}
