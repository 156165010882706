import Urls from "./assets/js/Urls";
import React from "react";
import Login from "./components/App/AppPublic/Auth/Login";
import Register, { RegisterReseller } from "./components/App/AppPublic/Auth/Register";
import Validate from "./components/App/AppPublic/Auth/Validate";
import Autologin from "./components/App/AppPublic/Auth/Autologin";
import Refresh from "./components/App/AppPublic/Auth/Refresh";
import NewPassword from "./components/App/AppPublic/Auth/NewPassword";
import Oauth from "./components/App/AppPublic/Auth/Oauth";
import Roundabout from "./components/App/AppPublic/Auth/Roundabout";
import Onboarding from "./components/Global/Onboarding/Onboarding";
import Projects from "./components/Global/Projects/Projects";
import NewFeedInProject from "./components/Global/Projects/NewFeedInProject";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Settings from "./components/Global/Settings/Settings";
import SubscriptionPage from "./components/Global/Settings/Subscription/SubscriptionPage";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import Configuration from "./components/Pages/Configuration/Configuration";
import { CampaignTypeIdentifier } from "./app/types/campaign";
import CatalogStatus from "./components/Pages/CatalogStatus/CatalogStatus";
import Optimisation from "./components/Pages/Optimisation/Optimisation";
import Labels from "./components/Pages/Labels/Labels";
import ProductEdit from "./components/Pages/Products/Edit/ProductEdit";
import GlobalViewPerformances from "./components/Pages/Performances/GlobalViewPerformances";
import SubCallback from "./components/Global/Settings/Subscription/Components/SubCallback";
import _Campaign from "./components/Pages/Campaigns/CampaignDetail/_Campaign";
import _ProductList from "./components/Pages/Products/_ProductList";
import _ProductsReporting from "./components/Pages/Reporting/_ProductsReporting";
import CampaignList from "./components/Pages/Campaigns/CampaignList/CampaignList";
import App from "./App";
import ErrorElement from "./ErrorElement";

const appRouter = createBrowserRouter([
  {
    path:"/",
    element: <App />,
    errorElement: <ErrorElement />,
    children: [
      // Auth Routes
      { path: Urls.auth.login, element: <Login /> },
      { path: Urls.auth.register, element: <Register /> },
      { path: Urls.auth.registerReseller(":code"), element: <RegisterReseller /> },
      { path: Urls.auth.validate, element: <Validate /> },
      { path: Urls.auth.validateToken(':token'), element: <Validate /> },
      { path: Urls.auth.autologin, element: <Autologin /> },
      { path: Urls.auth.refresh, element: <Refresh /> },
      { path: Urls.auth.refreshPassword(":token"), element: <NewPassword /> },
      { path: Urls.oauth, element: <Oauth /> },
      { path: Urls.auth.roundabout, element: <Roundabout /> },

      // Projects Routes
      { path: Urls.onboarding, element: <Onboarding /> },
      { path: Urls.projects.list, element: <Projects /> },
      { path: Urls.projects.newFeed(':project'), element: <NewFeedInProject /> },

      // Settings Routes
      { path: Urls.settings.me, element: <Settings /> },
      { path: Urls.settings.security, element: <Settings /> },
      { path: Urls.settings.subEdit(":feedId"), element: <SubscriptionPage /> },
      { path: Urls.settings.subList, element: <Settings /> },
      { path: Urls.settings.history, element: <Settings /> },
      { path: Urls.settings.accounts, element: <Settings /> },

      // App Routes
      { path: Urls.dash(":feedId"), element: <Dashboard /> },
      { path: Urls.configuration(":feedId"), element: <Configuration /> },
      { path: Urls.campaigns.list2(":feedId"), element: <CampaignList /> },
      { path: Urls.campaigns.edit(":feedId", ":provider", ":campaignId"), element: <_Campaign /> },
      { path: Urls.campaigns.new(":feedId", ":provider", ":type" as CampaignTypeIdentifier), element: <_Campaign /> },

      // Products Routes
      { path: Urls.products.catalog(":feedId"), element: <CatalogStatus /> },
      { path: Urls.products.list(":feedId"), element: <_ProductList /> },
      { path: Urls.products.optimisation(":feedId"), element: <Optimisation /> },
      { path: Urls.products.customLabels(":feedId"), element: <Labels /> },
      { path: Urls.products.edit(":feedId", ":pId"), element: <ProductEdit /> },

      // Performances Routes
      { path: Urls.perf.globalView(":feedId"), element: <GlobalViewPerformances /> },
      { path: Urls.perf.products(":feedId"), element: <_ProductsReporting /> },

      // Other Routes
      { path: Urls.subscription.callback(":feedId"), element: <SubCallback /> },
      { path: "/*", element: <Navigate to={Urls.auth.login} /> }
    ]
  }
]);

export default appRouter;
