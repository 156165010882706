import { SubInterface } from "../Subscription";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { useSubscription } from "./useSubscription";
import Urls from "../../../../../assets/js/Urls";
import feedApi from "../../../../../app/services/secured/feed";
import {
  CancelData,
  InitFeedApi,
  InitFeedResponse,
  PreviewSubscription,
  Price,
  UpdateSubscriptionApi
} from "../../../../../app/types/feed";
import { redirect } from "../../../../../app/slices/app";
import { useEffect, useMemo } from "react";
import { NewSuccessNotification } from "../../../../../app/slices/notifications";
import { getLocaleDate, sumReducer } from "../../../../../app/utils";
import { loadStripe } from "@stripe/stripe-js";
import { getShop, MIN_PLATFORMS } from "../../../../../app/slices/shop";
import { modalShowForceDisableChannel } from "../../../../../app/slices/modal";
import { Packs } from "../constants";
import { AdsProvider } from "../../../../../app/types/campaign";

const stripe = async (data: InitFeedResponse) => {
  const promise = await loadStripe(data.key);
  await promise?.redirectToCheckout({
    sessionId: data.session,
  });
}

export interface useHandleSubscriptionProps extends SubInterface {
  selectedProducts: Price[] | undefined,
}

type submitParams = {
  cancelData?: CancelData,
  callback: () => void,
  onPlatformsIssue?: () => void,
  hasDisabledChannels?: boolean,
  platforms_to_remove?: AdsProvider[],
}

export const useHandleSubscription = (p: useHandleSubscriptionProps) => {
  const { currSub, selectedProducts } = p;
  const shop = useAppSelector(getShop);
  const { nb_enabled } = shop.platforms;
  const dispatch = useAppDispatch();

  // API calls
  const [ initSub, initSubRes ] = feedApi.useInitSubscriptionMutation();
  const [ previewSub, previewSubRes ] = feedApi.useLazyPreviewSubscriptionQuery();
  const [ updateSub, updateSubRes ] = feedApi.useUpdateSubscriptionMutation();
  const [ cancelSub, cancelSubRes ] = feedApi.useCancelSubscriptionMutation();

  const [ sub ] = useSubscription(currSub?.feed);

  // Derived state
  const products = useMemo(() => {
    return [ currSub?.pack, currSub?.limit, currSub?.platform, currSub?.ai ].filter(Boolean) as number[];
  }, [ currSub ]);

  const additionalPlatforms = selectedProducts?.filter(s => s.type === "platforms") ?? [];
  const isNew = useMemo(() => sub.data?.is_new ?? true, [ sub.data ]);

  const redirect_success = process.env.REACT_APP_APP_DOMAIN + Urls.subscription.callback(currSub?.feed) + "?status=success";
  const redirect_error = process.env.REACT_APP_APP_DOMAIN + Urls.settings.subEdit(currSub?.feed);

  // API parameters
  const initObj: InitFeedApi = {
    feed: currSub?.feed,
    prices: products,
    redirect_error,
    redirect_success,
    promotion_code: currSub?.promotion_code,
  }

  const updateObj: UpdateSubscriptionApi = {
    feed: currSub?.feed,
    prices: products,
    confirm: true,
  }

  // Helper function
  const shouldShowForceDisableModal = () => {
    if (nb_enabled <= MIN_PLATFORMS) return false;
    if ((selectedProducts?.filter(p => p.type === "pack" && p.code === Packs.Premium).length || 0) > 0) return false;
    if (!additionalPlatforms.length) return true;
    const product = additionalPlatforms[0];
    const totalPlatformsAvailable = MIN_PLATFORMS + (product.additional_platforms ?? 0);
    return nb_enabled > totalPlatformsAvailable;
  };

  // Main submit function
  const submit = async (params: submitParams) => {
    const { cancelData, callback, onPlatformsIssue, hasDisabledChannels, platforms_to_remove } = params;
    try {
      if (!currSub?.pack) {
        await cancelSub({ feed: currSub?.feed, ...cancelData }).unwrap();
        return;
      }

      if (isNew) {
        await initSub(initObj).unwrap();
        return;
      }

      if (hasDisabledChannels) {
        await updateSub({
          ...updateObj,
          platforms_to_remove
        }).unwrap();
        callback();
        return;
      }

      if (shouldShowForceDisableModal()) {
        dispatch(modalShowForceDisableChannel(p));
        return;
      }

      await updateSub(updateObj).unwrap();
      callback();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const onSuccess = () => dispatch(redirect(Urls.settings.subList));

  useEffect(() => {
    if (initSubRes.isSuccess) {
      stripe(initSubRes.data);
    }
  }, [ initSubRes ]);

  /** Notifications */
  useEffect(() => {
    if (updateSubRes.isSuccess) {
      dispatch(NewSuccessNotification("subscription-edit-success"));
      onSuccess();
    }
  }, [ updateSubRes ]);

  useEffect(() => {
    if (cancelSubRes.isSuccess) {
      dispatch(NewSuccessNotification("subscription-cancel-success"));
      onSuccess();
    }
  }, [ cancelSubRes ]);


  useEffect(() => {
    if (!isNew && (currSub?.pack) && currSub?.feed && !sub.data?.next_periods) {

      const arr = [ currSub.pack ];

      if (currSub) {
        currSub.limit > 0 && arr.push(currSub?.limit);
        currSub.platform && arr.push(currSub?.platform);
        currSub.ai && arr.push(currSub.ai);
      }

      previewSub({
        feed: currSub?.feed,
        prices: arr,
        confirm: false,
      }, true)
    }
  }, [ isNew, currSub?.pack, currSub?.limit, sub.data, currSub?.platform, currSub?.ai ]);

  const previewData = previewSubRes.data as PreviewSubscription;
  const toPayToday = previewData?.lines.filter(p => p.current_period).map(p => p.amount_proration).reduce(sumReducer, 0);
  const toPayNext = currSub?.pack
    ? previewData?.lines.filter(p => !p.current_period).map(p => p.amount_monthly).reduce(sumReducer, 0)
    : 0;

  const linesNext = previewData?.lines.filter(p => !p.current_period) ?? [];
  const dateNext = linesNext.length
    ? getLocaleDate(new Date(linesNext[0]?.period.start * 1000).toDateString(), true)
    : null;

  return [
    {
      submit,
      preview: previewData,
      toPayToday,
      toPayNext,
      dateNext,
      isLoading: previewSubRes.isFetching,
      isNew,
    },
    !currSub?.pack ? cancelSubRes : isNew ? initSubRes : updateSubRes,
    cancelSubRes,
  ] as const;
};
