import AppSecured from "../../App/AppSecured/AppSecured";
import { FormattedMessage } from "react-intl";
// import { Tab } from "react-bootstrap";
import Profile from "./Pages/Profile";
import { BackButton, ColoredBadge, PageHeader } from "../../App/Blocks/Misc";
import Urls from "../../../assets/js/Urls";
import Security from "./Pages/Security";
import Subs from "./Subs/Subs";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getUser, resetCurrentFeed } from "../../../app/slices/app";
import profileApi from "../../../app/services/secured/profile";
import History from "./Pages/History";
import { Box, Tab as TabMU, Tabs, Typography } from "@mui/material";
import { TabComponent, TabPanel } from "../../App/Blocks/AppTabs";
import Accounts from "./Pages/Accounts";

export type SettingsTabs = "me" | "subs" | "accounts" | "history";

const Settings = () => {

  const dispatch = useAppDispatch();
  const path = document.location.pathname;

  const [ key, setKey ] = useState<SettingsTabs>("me");

  const query = profileApi.useGetProfileQuery();
  const needs_link = query.data?.merchant.address.address === null;

  const SettingsComponent: TabComponent<SettingsTabs> = {
    "me": {
      title: <ColoredBadge display={needs_link}>
        <FormattedMessage id={"informations"}/>
      </ColoredBadge>,
      tab: <Profile/>,
      link: Urls.settings.me,
      tour_page: null,
    },
    "subs": {
      title: <FormattedMessage id={"subscriptions"}/>,
      tab: <Subs/>,
      link: Urls.settings.subList,
      disabled: needs_link,
      tour_page: null,
    },
    accounts: {
      title: <FormattedMessage id={'linked-channels'}/>,
      tab: <Accounts/>,
      link: Urls.settings.accounts,
      tour_page: null,
    },
    history: {
      title: <FormattedMessage id={"history"}/>,
      tab: <History/>,
      link: Urls.settings.history,
      disabled: false,
      tour_page: null,
    }
  }

  useEffect(() => {
    if (path.includes("subscriptions") && !needs_link) {
      setKey("subs");
    } else if (path.includes("history")) {
      setKey("history");
    } else if (path.includes("accounts")) {
      setKey("accounts");
    } else {
      setKey("me");
    }
  }, [ path ]);

  useEffect(() => {
    dispatch(resetCurrentFeed());
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: SettingsTabs) => {
    setKey(newValue);
  };

  return (
    <AppSecured lockNav={true}>

      <Box sx={{display:"flex", gap: 1, alignItems:"center"}}>
        <BackButton href={Urls.projects.list} />
        <Typography variant={"h4"}>
          <FormattedMessage id={'settings'} />
        </Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
        <Tabs value={key} onChange={handleChange}>
          {Object.keys(SettingsComponent).map(c =>
            <TabMU
              disabled={SettingsComponent[c as SettingsTabs].disabled}
              value={c}
              label={SettingsComponent[c as SettingsTabs].title}
            />)}
        </Tabs>
      </Box>

      <Box>
        {Object.keys(SettingsComponent).map(c => <TabPanel index={key} value={c} key={`pane-${c}`}>
          {SettingsComponent[c as SettingsTabs].tab}
        </TabPanel>)}
      </Box>
    </AppSecured>
  )
}

export default Settings;
