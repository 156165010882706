import { MemoizedNavigationItem } from "./NavigationItem";
import {
  AppRegistration,
  AutoAwesome,
  Dashboard,
  Insights,
  LabelImportant,
  PieChart,
  SellOutlined,
  TableView
} from "@mui/icons-material";
import Urls from "../../../assets/js/Urls";
import { NavDivider } from "../NavComponents";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { List } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { getNavOpen } from "../../../app/slices/app";
import { GetCurrentFeedId } from "../../../app/utils";
import { AppSecuredProps } from "../AppSecured/AppSecured";
import { useFeedDownloadError } from "../../../app/hooks/customHooks";
import { getShopAccounts } from "../../../app/slices/shop";
import useChannelErrors from "../../../app/hooks/useChannelErrors";
// import { AlertTypes } from "../../../app/types/profile";

const NavList = ({ lockNav }: Pick<AppSecuredProps, "lockNav">) => {

  const feed = GetCurrentFeedId();
  const open = useAppSelector(getNavOpen);

  const hasSrc = useAppSelector(state => state.shop.hasSource);
  const hasMerchantAlerts = useAppSelector(state => state.shop.accounts.hasAlerts.google);
  const { hasPaymentIssue, hasTrackingIssue } = useChannelErrors();

  const hasAccountsAlerts = false; // TODO - Waiting for new account access management
  const hasFeedDownloadError = useFeedDownloadError().hasError;

  const diffusionError = hasMerchantAlerts || hasAccountsAlerts;
  const feedError = (!hasSrc && (feed > 0)) || hasFeedDownloadError;

  const displayConfigurationError = diffusionError || feedError || hasPaymentIssue || hasTrackingIssue;

  return (
    <List>
      <MemoizedNavigationItem
        icon={<Dashboard fontSize={"small"}/>}
        href={Urls.dash(feed)}
        title={"dashboard"}
        isOpen={open}
        disabled={lockNav}
      />

      <MemoizedNavigationItem
        icon={<AppRegistration fontSize={"small"}/>}
        href={Urls.configuration(feed)}
        title={"Configuration"}
        isOpen={open}
        disabled={lockNav}
        alert={displayConfigurationError}
      />

      <NavDivider section={"diffusion"} open={open}/>

      <MemoizedNavigationItem
        icon={<Insights fontSize={"small"}/>}
        href={Urls.perf.globalView(feed)}
        title={"global-performances"}
        isOpen={open}
        disabled={lockNav}
      />

      <MemoizedNavigationItem
        icon={<TableView fontSize={"small"}/>}
        href={Urls.perf.products(feed)}
        title={"product-performances"}
        isOpen={open}
        disabled={lockNav}
      />

      <MemoizedNavigationItem
        icon={<AdsClickIcon fontSize={"small"}/>}
        href={Urls.campaigns.list2(feed)}
        title={"campaigns-handle"}
        isOpen={open}
        disabled={lockNav}
      />

      <NavDivider section={"products-catalog"} open={open}/>

      <MemoizedNavigationItem
        icon={<PieChart fontSize={"small"}/>}
        href={Urls.products.catalog(feed)}
        title={"catalog-state-nav"}
        isOpen={open}
        disabled={lockNav}
      />

      <MemoizedNavigationItem
        icon={<SellOutlined fontSize={"small"}/>}
        href={Urls.products.list(feed)}
        title={"product-list"}
        isOpen={open}
        disabled={lockNav}
      />

      <MemoizedNavigationItem
        icon={<LabelImportant fontSize={"small"}/>}
        href={Urls.products.customLabels(feed)}
        title={"custom-labels"}
        isOpen={open}
        disabled={lockNav}
      />

      <MemoizedNavigationItem
        icon={<AutoAwesome fontSize={"small"}/>}
        href={Urls.products.optimisation(feed)}
        title={"feed-optimisation"}
        isOpen={open}
        disabled={lockNav}
      />
    </List>
  )
}

export default React.memo(NavList);
