const en: Record<string, string> = {
  "login": "Login",
  "comeback": "We are delighted to see you again, but you still need to log in!",
  "continue": "Continue",
  "no-account": "Don't have an account?",
  "register": "Register",
  "already-have-account": "Already have an account?",
  "your-name": "Your company",
  "your-email": "Your email",
  "password": "Password",
  "confirmation": "Confirmation",
  "forgotten-password": "Forgot password?",
  "password-requirements": "Your password should contain:",
  "register-catch": "It's fast, it's free, and it's essential to go further",
  "password-minimum-length": "At least 6 characters",
  "password-lowercase": "Lowercase letters (a-z)",
  "password-uppercase": "Uppercase letters (A-Z)",
  "password-numbers": "Numbers (0-9)",
  "pleaseWait": "Please wait...",
  "dashboard": "Dashboard",
  "dashboard-desc": "This is where everything happens",
  "performances": "Performances",
  "performances-desc": "Track your numbers",
  "products": "Products",
  "products-desc": "Manage your products",
  "advertisement": "Advertisement",
  "advertisement-desc": "Manage your campaigns",
  "academy": "Academy",
  "academy-desc": "Learn from the best",
  "nearly-there": "Almost there!",
  "validate-email": "Click on the link you just received by email to finalize your registration.",
  "token-verification": "Verification in progress...",
  "account-validate-success": "Your account has been successfully verified! You can now log in.",
  "logout": "Logout",
  "active": "Active",
  "inactive": "Inactive",
  "performances-page-title": "Performances",
  "performances-page-desc": "Here's an overview of your advertising performance.",
  "datepicker-today": "Today",
  "datepicker-yesterday": "Yesterday",
  "datepicker-this-week": "Last 7 days",
  "datepicker-this-month": "Last 30 days",
  "datepicker-previous-month": "Previous month",
  "datepicker-this-year": "This year",
  "datepicker-period-selected": "From {from} to {to}",
  "datepicker-last-year": "Last year",
  "impressions": "Impressions",
  "clicks": "Clicks",
  "spent": "Spent",
  "conversions": "Sales",
  "conversionValue": "Revenue",
  "dashboard-page-title": "Dashboard",
  "expertise": "Expertise Level",
  "expertise-desc": "Complete the checklist missions to progress",
  "amateur": "Amateur",
  "recommandations": "Recommendations",
  "recommandations-desc": "Follow the recommendations to increase your performance score",
  "recommandations-available": "Available Recommendations",
  "recommandations-explanation": "Recommendations help you improve your optimization score.",
  "see-recommandations": "See Recommendations",
  "performance-score": "Performance Optimization Score",
  "performance-score-explanation": "The optimization score is determined based on several factors related to the quality of your product feed and the overall configuration of your advertising accounts.\n\nThe higher your score, the more likely you are to launch profitable campaigns!",
  "more": "Learn more",
  "how-to-account": "How to create an account?",
  "products-page-title": "Products",
  "products-page-desc": "This is where you configure everything related to your products and imported catalogs",
  "global-view": "Overview",
  "product-list": "Products",
  "categories": "Categories",
  "feed-handle": "Feed Management",
  "active-products": "Active",
  "warnings": "Warnings",
  "waiting-validation": "Waiting",
  "refused-products": "Refused",
  "catalog-state": "Overall Product Catalog State",
  "catalog-errors-recap": "Product Feed Errors",
  "error-desc": "Error Description",
  "error-products": "Affected Products",
  "valid": "Valid",
  "stock": "In Stock",
  "invalid": "Blocked",
  "out-of-stock": "Out of Stock",
  "search-by": "Search among {by}",
  "feed-product-state": "Product State by Advertising Channels",
  "feed-product-state-desc": "Track the validation status of your products based on your active advertising channels",
  "p-refused": "Refused",
  "p-waiting": "Waiting",
  "p-warning": "Warning",
  "p-valid": "Validated",
  "feed-info": "Feed Information",
  "feed-info-desc": "Modify feed information",
  "name": "Name",
  "feed-reload": "Refresh Feed",
  "save-edits": "Save Changes",
  "reference": "Reference",
  "state": "State",
  "state-google": "Status",
  "category": "Category",
  "brand": "Brand",
  "status": "Status",
  "no-products": "No products!",
  "no-products-desc": "Easily import your products from your online store",
  "import-products": "Import my products",
  "no-feed": "No feed!",
  "no-feed-desc": "Import a data feed to retrieve your products",
  "import-feed": "Import my feed",
  "new-feed-import": "Import a product feed",
  "edit-feed-success": "Feed edit successful. Importing products, this may take several minutes...",
  "feed-from-shop": "Choose an import method",
  // "feed-from-others": "Other import methods",
  "import-feed-desc": "Follow the instructions to import your product feed.",
  "import-feed-by": "Import a new feed via {by}",
  "api-key": "API Key",
  "token": "Token",
  "campaigns": "Campaigns",
  "optimisation": "Optimization",
  "ads-account": "Advertising Accounts",
  "conversion-follow": "Conversion Tracking",
  "no-campaign": "No campaigns!",
  "no-campaign-desc": "No campaigns found with these filters.",
  "new-campaign": "New campaign",
  "projects": "Projects",
  "projects-manage": "My Projects",
  "welcome": "Welcome!",
  "welcome-desc": "We would like to get to know you better to personalize your experience 🥳",
  "firstName": "First Name",
  "lastName": "Last Name",
  "who-are-you": "Who are you?",
  "what-situation": "What is your situation?",
  "how-do-you-know-us": "How did you hear about us?",
  "select": "Select...",
  "for-myself": "I use Feedcast for my own store",
  "for-customers": "I use Feedcast for my clients",
  "on-google": "On Google",
  "on-facebook": "On Facebook / Instagram",
  "on-linkedin": "On Linkedin",
  "on-blog": "Via the blog / academy",
  "on-people": "Through word of mouth",
  "on-other": "Other...",
  "thanks": "Thank you",
  "thanks-desc": "Now we will create your first project together and add the URL of your store.",
  "confirm-creation": "Confirm the creation of my project",
  "ads-accounts": "Advertising Accounts",
  "no-accounts": "No accounts!",
  "no-accounts-desc": "Connect or create an advertising account to start advertising on Feedcast",
  "begin": "Begin",
  "perf-max": "Performance Max",
  "prospection": "Prospecting",
  "retargeting": "Retargeting",
  "smart-shopping": "Smart Shopping",
  "settings": "Settings",
  "my-account": "My Account",
  "subscriptions": "Subscriptions",
  "staff": "Staff",
  "affiliation": "Affiliation",
  "notifications": "Notifications",
  "refresh-password": "Reset my password",
  "refresh-password-success": "You will receive an email to reset your password!",
  "new-password": "New password",
  "change-password": "Change my password",
  "success-new-password": "Your password has been successfully changed. You can now log in!",
  "profile-edit-success": "Successfully edited your information",
  "security": "Security",
  "project-name": "Project Name",
  "confirm-new-project": "Create a new project",
  "new-project-success": "New project created successfully",
  "add-feed": "Add a new feed",
  "new-feed-success": "Feed created successfully",
  "new-feed": "Connect a new product feed",
  "new-project": "New project",
  "select-source": "Select the source",
  "get-feed": "Retrieve the feed",
  "preview": "Preview",
  "tuto-steps": "Steps to connect your store",
  "new-feed-info": "Your feed information",
  "new-feed-info-desc": "Follow the tutorial to link your store and connect your product catalog",
  "new-feed-url": "URL of your {source} product feed",
  "continue-with": "Continue with",
  "store": "Store URL",
  "api_key": "API Key",
  "access_token": "Access Token",
  "success-onboarding": "Your project has been successfully created. You can now add",
  "add-new-feed": "Create my new store",
  "new-feed-name": "Domain name of your store",
  "new-feed-country": "Country/Language of distribution",
  "optional": "Optional",
  "fictif": "Optional",
  "feed-without-url": "Fictitious feed",
  "feed-without-url-desc": "You can configure product import later.",
  "how-to-multiple-locales": "How to advertise in multiple countries?",
  "how-to-multiple-locales-desc": "To advertise the same feed in another country, create a new feed.",
  "found-results": "result(s)",
  "new": "New",
  "refurbished": "Refurbished",
  "used": "Used",
  "condition": "Condition",
  "availability": "Availability",
  "in_stock": "In Stock",
  "out_of_stock": "Out of Stock",
  "backorder": "Backorder",
  "product-edit-success": "Product edit successful",
  "profile-info": "Profile Information",
  "invoice-info": "Billing Information",
  "auth-email": "Your login email (username)",
  "your-first-name": "Your first name",
  "your-last-name": "Your last name",
  "your-phone": "Your phone number",
  "business-name": "Business name",
  "business-phone": "Headquarters phone",
  "invoice-email": "Billing email address",
  "business-address": "Headquarters address",
  "business-address-2": "Address (additional)",
  "zip": "Postal code",
  "city": "City",
  "country": "Country",
  "current-password": "Current password",
  "confirm-new-password": "Change my password",
  "new-password-success": "Successfully changed your password.",
  "found-products": "products found",
  "missing-gtin": "Missing GTIN(s)",
  "missing-categories": "Missing categories",
  "missing-images": "Missing images",
  "missing-ids": "Missing IDs",
  "feed-edit-success": "Feed edit successful",
  "feed-max-import": "In the free plan, the number of products imported into Feedcast is limited to 100.",
  "category-google": "Corresponding category",
  "category-auto-link": "Confirm automatic linking",
  "category-map-success": "Category mapping successful",
  "products-imported": "Products imported into Feedcast",
  "products-errors": "Refused",
  "projects-handle": "Manage my projects",
  "google-desc": "Advertise your product campaigns \n" +
    "across the entire Google network: Shopping, Search, Display, \n" +
    "Discover, Gmail, YouTube...",
  "meta-desc": "Advertise your product campaigns \n" +
    "on Facebook & Instagram: acquisition campaigns, DSA, retargeting...",
  "amazon-desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida vestibulum fermentum. Nunc eu risus .",
  "microsoft-desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida vestibulum fermentum. Nunc eu risus .",
  "pinterest-desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida vestibulum fermentum. Nunc eu risus .",
  "FEED_DOWNLOAD_ERROR": "Feed download error",
  "add-ads-account": "Add an advertising account",
  "add-ads-account-desc": "Select the channel on which you want to connect an advertising account",
  "success-ads-account-link": "Advertising account linking successful",
  "add-campaign": "Create a campaign",
  "success-ads-account-create": "Advertising account creation successful",
  "link-ads-account": "Link your advertising account",
  "connect-with": "Connect with",
  "create-ads-account": "Create an advertising account",
  "create-ads-account-google": "Create a new Google Ads account",
  "without-project": "Without project",
  "success-edit-campaign": "Campaign edit successful",
  "yes": "Yes",
  "no": "No",
  "campaign-enable": "Enable my campaign",
  "campaign-enable-confirm": "Are you sure you want to enable your campaign?",
  "campaign-disable": "Disable my campaign",
  "campaign-disable-confirm": "Are you sure you want to disable your campaign?",
  "go": "Google",
  "fb": "Meta",
  "conversion": "Sales",
  "campaigns-desc": "Track the progress of your advertising campaigns in real time.",
  "subscription": "Subscription",
  "GOOGLE_LISTING": "Google Listing SEO Ads",
  "GOOGLE_LISTING_DESC": "Display your products in the Google Shopping tab",
  "INSTA_SHOPPING": "Instagram Ads",
  "INSTA_SHOPPING22": "Instagram Ads",
  "INSTA_SHOPPING_DESC": "Promote your product catalog on Instagram",
  "INSTA_SHOPPING22_DESC": "Promote your product catalog on Instagram",
  "google-shopping-ads": "Google Shopping Ads",
  "google-shopping-ads-desc": "Advertise your products on the Google Search Network, Display Network, YouTube, and Gmail",
  "meta-ads": "Meta Ads",
  "meta-ads-desc": "Promote your product catalog on Facebook",
  "LIM2500": "2500 products",
  "LIM5000": "5000 products",
  "LIM10000": "10,000 products",
  "LIM20000": "20,000 products",
  "subscription-success": "Subscription successful",
  "missing-invoice-info": "Missing billing information",
  "missing-invoice-info-desc": "Provide my billing information",
  "create-first-feed": "Add a new feed",
  "no-data-yet": "No data!",
  "no-data-yet-desc": "Your advertising performance will be displayed here when one of your campaigns is active.",
  "add-account": "Add an account",
  "connected-leverage": "Connected channels",
  "canal": "Channel",
  "options": "Options",
  "cancel-subscription": "Cancel my subscription",
  "no-campaign-2-desc": "We found no advertising campaigns based on your filters.",
  "refresh-products": "Refresh the product list",
  "autopilot-desc": "The Autopilot subscription allows you to advertise on 2 channels (Google Shopping Ads & Facebook Ads) with a maximum of 1000 products.",
  "ht-month": "Excl. Tax/month",
  "already-in-subscription": "Included in your subscription",
  "included": "Included",
  "per-month": "/month",
  "add": "Select",
  "remove": "Remove",
  "feedcast-plan": "Feedcast Plan",
  "autopilot-plan": "Autopilot Plan",
  "max-products": "Up to {p} products",
  "update-subscription": "Update my subscription",
  "subscription-desc": "Manage the subscription of your feed.",
  "trial": "Trial",
  "canceled": "Canceled",
  "save": "Save",
  "update": "Update",
  "alerts": "Alerts",
  "contact": "Contact",
  "email": "Email",
  "phone": "Phone",
  "address": "Address",
  "address-full": "Postal address",
  "shipping-cost": "Shipping policy",
  "phone-validation": "Merchant Center account verification",
  "free": "Free",
  "fixed": "Fixed",
  "ranged": "Range (order amount)",
  "ranged-weight": "Range (weight)",
  "delivery-min": "Minimum delivery",
  "delivery-max": "Maximum delivery",
  "handling-min": "Minimum handling days",
  "handling-max": "Maximum handling days",
  "max-hour": "Max hour",
  "merchant-update-success": "Merchant Center information update successful",
  "fixed-price": "Fixed price",
  "my-ads-accounts": "My advertising accounts",
  "no-project": "Untitled",
  "soon": "Coming soon!",
  "valid-code": "Validate my code",
  "begin-phone-verification": "Receive a verification SMS",
  "received-code": "Code received via SMS",
  "budget": "Budget",
  "assets-manager": "Assets Manager",
  "assets-manager-desc": "Manage your creative assets to associate them with your campaigns: images, videos...",
  "upload-asset-success": "Asset upload successful",
  "global-parameters": "Campaign",
  "global-parameters-desc": "Configure the name and daily budget of your campaign.",
  "targeting-parameters": "Which brand creative elements will we use?",
  "targeting-parameters-desc": "Ajouter des éléments créatifs à une campagne améliore la pertinence et les performances des annonces.",
  "added": "selected",
  "valid-selection": "Validate my selection",
  "previous": "Previous",
  "next": "Next",
  "GLOBAL": "General",
  "TARGETING": "Targeting",
  "element-group-name": "Element group name",
  "first-element-group": "My first element group",
  "company-name": "Company name",
  "url-final": "Final URL",
  "long-titles": "Long titles",
  "short-titles": "Short titles",
  "descriptions": "Descriptions",
  "add-pictures": "Add pictures",
  "import-pictures": "Import pictures",
  "create-campaign": "Create my campaign",
  "edit-campaign": "Edit my campaign",
  "diffusion-country": "In which language(s) and country(s)?",
  "maximise-conversion": "Maximize conversion value",
  "define-roas": "Set a return on ad spend goal (optional)",
  "roas-target": "ROAS Target",
  "auctions": "Auctions",
  "add-logo": "Add logos",
  "add-marketing-image-square": "Add marketing images (square)",
  "add-marketing-image": "Add images (landscape)",
  "fetching-campaign": "Fetching your campaign information...",
  "success-edition-campaign": "Your changes have been saved.",
  "PRODUCTS": "Products",
  "products-labels": "Which products should we advertise?",
  "products-labels-desc": "Select the set of products you want to advertise.",
  "small-description": "Short description (60 characters max)",
  "references": "References",
  "brands": "Brands",
  "custom-labels": "Labels",
  "create-custom-label": "New label",
  "eq": "Is equal to",
  "in": "Is in",
  "custom_label_0_k": "Labels 1",
  "custom_label_1_k": "Labels 2",
  "custom_label_2_k": "Labels 3",
  "custom_label_3_k": "Labels 4",
  "custom_label_4_k": "Labels 5",
  "label0": "Labels 1",
  "label1": "Labels 2",
  "label2": "Labels 3",
  "label3": "Labels 4",
  "label4": "Labels 5",
  "success-dynamic-label": "Dynamic label edition successful",
  "custom-group": "Label group",
  "label-value": "Label name",
  "apply-label-if": "Apply label if",
  "all-conditions-met": "All conditions met",
  "one-condition-met": "At least one condition met",
  "overwrite-value": "Overwrite label value if already existent",
  "attribute": "Attribute",
  "value": "Value",
  "add-filter": "Add filter",
  "delete": "Delete",
  "edit-custom-label": "Edit dynamic label",
  "very-short-title": "Very short title (15 characters max)",
  "mandatory": "Mandatory",
  "youtube-ids": "YouTube Videos (optional)",
  "strategy": "What is your bidding strategy?",
  "strategy-desc": "By default, a Feedcast Warm-up campaign displays ads for your entire catalog, but you can specify your selection as needed.",
  "all-countries-territories": "All countries and territories",
  "choose-location-filters": "Choose another geographic area",
  "country-from-feed": "{country} (based on the country of the product feed)",
  "select-countries": "Specify the country/countries to target",
  "select-languages": "Specify the language(s) to target",
  "preselected-language": "Preselected language from your feed",
  "select-your-strategy": "Select your bidding strategy",
  "manual_cpc": "Manual CPC bidding",
  "target_roas": "Target ROAS",
  "maximise-clicks": "Maximize clicks",
  "activate-manual-cpc": "Activate CPC optimization (can improve conversion volume)",
  "optimise-for-conversion": "Optimize for conversions",
  "optimise-for-value": "Optimize for maximum conversion value",
  "roas-value": "ROAS Value",
  "maximal-cpc": "Set a maximum CPC bid limit",
  "bid-limit": "Bid limit",
  "obselete-campaign-type": "Warning! This campaign type is becoming increasingly obsolete.",
  "rather-create": "Instead, create a ",
  "perf-max-campaign": "Performance Max campaign",
  "import-videos": "Import videos",
  "success-creation-campaign": "Your new campaign has been successfully created!",
  "cpc": "CPC",
  "clickRate": "Click Rate",
  "conversionCost": "Cost/Conv",
  "roi": "ROI",
  "campaign": "Campaign",
  "toast-success": "Success!",
  "toast-danger": "Error",
  "toast-warning": "Warning!",
  "toast-info": "Info:",
  "feedcast-campaign": "Feedcast Campaign",
  "copied-to-clipboard": "Copied to clipboard!",
  "period-recap": "Period Recap",
  "target-roas": "Target ROAS Value",
  "diffusion-country-desc": "You can specify the countries where you want to advertise, as well as the language(s) of the targeted audience.",
  "classic-shopping-outdated": "This campaign type is becoming outdated! Launch a Performance Max campaign instead ⬇️",
  "classic-shopping-outdated-desc": "Performance Max campaigns use your conversion goals and the best of Google Ads automation to deliver ads across all Google properties. This way, you can reach your customers at the right moment with the right ad.",
  "switch-all-countries": "Check to automatically target all countries",
  "all-products": "All my products with {type}s",
  "all-products-desc": "(Recommended, especially if it's your first campaign)",
  "specific-products": "Only those in my selection",
  "specific-products-desc": "(If you know what you're doing)",
  "migrate-to-perf-max": "Migrate to Performance Max",
  "google-perf-max-alert": "With Google Performance Max, it's even easier!",
  "perf-by-products": "Google Product Performance",
  "product": "Product",
  "product-not-found": "This product does not exist in your feed",
  "projects-title": "How to use my Feedcast projects?",
  "projects-desc": "Your projects allow you to group your feeds under a single entity. If you want to change the project of a feed, simply drag and drop it into the desired project!",
  "devices-repartition": "Devices Distribution",
  "desktop": "Desktop",
  "mobile": "Mobile",
  "tablet": "Tablet",
  "catalog-status": "Catalog Status",
  "accepted-filetypes": "Supported formats: JPEG, PNG, MP4, PDF",
  "drag-drop-or": "Drag and drop or ",
  "browse-files": "browse your files",
  "one-picture-required": "You must add at least one image",
  "valid-products": "Validated Products",
  "new-campaign-desc": "Select how you want to promote your products!",
  "choose-format": "Choose this format",
  "perf-max-desc": "The Performance Max format allows you to run a single campaign across the entire Google Ads network, including Search, Display, Discover, Gmail, Google Maps, and YouTube, using machine learning and automation.",
  "shopping": "Classic Shopping",
  "shopping-desc": "Advertise across all Google platforms to reduce friction and generate better performance (such as Smart Shopping campaigns).",
  "recommended": "Recommended",
  "advanced": "Advanced",
  "required-activation": "Activation Required",
  "required-activation-desc": "Verify your account before you can create campaigns!",
  "google-campaigns-loading": "Loading Google campaigns...",
  "meta-campaigns-loading": "Loading Meta campaigns...",
  "loading-campaigns": "Loading campaigns...",
  "coming-soon": "Coming soon!",
  "new-feed-desc": "Click on one of the available sources to configure the automatic import of all your products into Feedcast!",
  "previous-step": "Previous step",
  "no-tutorial": "No tutorial",
  "no-tutorial-desc": "There is no tutorial available for this source. Feel free to ask for help on the chat!",
  "download-csv": "Download as CSV",
  "create-dynamic-label": "Create a dynamic label",
  "product-state": "Product State",
  "all-states": "All states",
  "edit-project": "Edit Project",
  "no-accounts-linked": "No linked ad accounts",
  "edit-project-success": "The project has been successfully edited!",
  "create-ads-account-company": "Create a {company} Ads account",
  "identifier": "Identifier",
  "currency": "Currency",
  "feed-original": "Original Product Feed",
  "feed-feedcast": "Feedcast Product Feed",
  "link": "Link",
  "gtin": "GTIN",
  "description": "Description",
  "title": "Title",
  "no-product-data": "No data!",
  "no-product-data-desc": "We have no data regarding the status of your Google catalog.",
  "pending": "Pending",
  "FLAG_PENDING": "Validation",
  "FLAG_ERROR": "Errors",
  "FLAG_ONLINE": "Valid",
  "FLAG_WARNING": "Warnings",
  "FLAG_ERROR_WARNING": "Errors & Warnings",
  "FLAG_ONLINE_WARNING": "Valid & Warnings",
  "fill-with-infos": "Fill with my billing information",
  "edit-multiple-products": "Edit Multiple",
  "edit-multiple-products-desc": "You can edit common properties simultaneously! ⤵️",
  "close": "Close",
  "select-google-category": "Choose a Google category for the selected products",
  "success-edit-multiple-products": "Your products have been successfully modified.",
  "chars-left": "char. left",
  "validating": "Validating",
  "google-invoice-info": "Billing Information",
  "next-step": "Next Step",
  "assets-group-name": "Asset Group Name",
  "go-to-v2": "Old Version",
  "trial-period": "Trial Period",
  "active-subscription": "Active Subscription",
  "canceled-subscription": "Canceled Subscription",
  "sub-status": "Subscription Status",
  "next-invoice": "Next Invoice",
  "current-price": "Current Amount",
  "date-end-sub": "Subscription End Date",
  "sub-canceled": "Canceled Subscription",
  "sub-canceled-desc": "You have canceled the renewal of your subscription. For now, it is not possible to restart a canceled subscription. Contact a support member via chat if you need assistance!",
  "disabled-campaign-creation-google": "You must first enable your Merchant Center before you can create a Google campaign.",
  "paid": "Paid",
  "open": "Open",
  "void": "Unknown",
  "invoices": "Invoices",
  "date-creation": "Creation Date",
  "amount": "Amount",
  "website": "Website",
  "start-subscription": "Start of Subscription",
  "auto-renew": "Auto-renewal",
  "catalog": "Your Meta Product Catalog",
  "catalog-desc": "Configure your product catalog associated with Meta here. If the catalog does not exist yet, simply choose a Pixel to link with your product catalog!",
  "select-pixel": "Select a Meta Pixel from the list",
  "search-for-pixel": "Search for a pixel...",
  "catalog-name": "Name of your Meta Product Catalog",
  "config-pixel-meta": "Configure your product catalog!",
  "config-pixel-meta-desc": "Once configured, your Feedcast product feed will be linked to your Pixel, and you can run Meta campaigns ⤵️",
  "config-merchant": "Configure your new Merchant Center account!",
  "config-merchant-desc": "We have created a brand new account for you. Use SMS verification to start running Google campaigns ⤵️",
  "create-catalog": "Configuration",
  "connected-accounts": "Connected Accounts",
  "success-meta-catalog": "Successfully created your Meta product catalog!",
  "building-view": "Under Construction View!",
  "building-view-desc": "This view is currently under construction.",
  "meta-classic": "Classic Meta",
  "meta-classic-desc": "Advertising across all Meta platforms. Choose your channel, events, and target audience for your campaigns.",
  "meta-campaign-type": "Meta Campaign Type",
  "meta-campaign-type-desc": "Retargeting or Prospecting?",
  "number-of-days": "Number of days since",
  "meta-page": "Meta Page",
  "meta-page-desc": "Link your Meta page with your website!",
  "select-a-page": "Select one of your pages...",
  "meta-global": "Global Settings",
  "meta-global-desc": "Specify the general settings here",
  "campaign-name": "Campaign Name",
  "adset-group-name": "Adset Group Name",
  "age-min": "Min Age",
  "age-mix": "Max Age",
  "AddToCart": "Added to Cart",
  "ViewContent": "Viewed Content",
  "Purchase": "Purchased",
  "begin-feedcast-diffusion": "To start promoting your products through Feedcast, choose the channels where you want to be visible!",
  "products-google": "Google Products",
  "active-with-warnings": "Active (with warnings)",
  "all-campaigns": "All My Campaigns",
  "imported-products": "{nb} imported products",
  "fr_FR": "France / FR",
  "en_GB": "United Kingdom / GB",
  "fr_BE": "France & Belgium / FR + BE",
  "fr_LU": "France & Luxembourg / FR + LU",
  "fr_CA": "France & Canada / FR + CA",
  "fr_CH": "France & Switzerland / FR + CH",
  "it_CH": "Italy & Switzerland / FR + IT",
  "de_CH": "Germany & Switzerland / DE + CH",
  "it_IT": "Italy / IT",
  "de_DE": "Germany / DE",
  "es_ES": "Spain / ES",
  "en_US": "UK & USA / US",
  "nl_BE": "Netherlands & Belgium / NL + BE",
  "nl_NL": "Netherlands / NL",
  "se_SE": "Sweden / SE",
  "pt_PT": "Portugal / PT",
  "es_MX": "Spain & Mexico / ES + MX",
  "no_NO": "Norway / NO",
  "en_AU": "UK & Australia / EN + AU",
  "pl_PL": "Poland / PL",
  "ae_AE": "United Arab Emirates / AR",
  "sa_SA": "Saudi Arabia / AR",
  "custom-labels-desc": "Custom labels allows you to create groups of products and segment your Feedcast campaigns",
  "custom-labels-desc-2": "You can choose to group your products by brand, by condition, by reference, or a combination of these three.",
  "custom-labels-desc-3": "You can choose to target a campaign to the selected product group(s) or, on the contrary, exclude them.",
  "my-custom-labels": "My Custom Labels",
  "project": "Project",
  "products-pending": "Pending",
  "no-campaigns": "You have no campaigns!",
  "no-oauth": "No connected accounts!",
  "add-oauth": "Link My Accounts",
  "no-create-campaigns": "Configure Your Services!",
  "configure-services": "Configure",
  "create-campaigns": "Create a Campaign",
  "active-campaigns": "Active Campaigns!",
  "diffuse-from": "Promote your product campaigns across the entire {company} network",
  "disabled-campaign-creation-meta": "Configure your Meta Catalog before creating a campaign.",
  "UNSPECIFIED": "Unspecified",
  "UNKOWN": "Unknown",
  "CAMPAIGN_REMOVED": "The campaign has been removed.",
  "CAMPAIGN_PAUSED": "The campaign is paused.",
  "CAMPAIGN_PENDING": "The campaign is scheduled to launch later.",
  "CAMPAIGN_ENDED": "The campaign has ended.",
  "CAMPAIGN_DRAFT": "The campaign is in draft mode.",
  "BIDDING_STRATEGY_MISCONFIGURED": "Bidding strategy misconfigured.",
  "BIDDING_STRATEGY_LIMITED": "Bidding strategy limited due to lack of data.",
  "BIDDING_STRATEGY_LEARNING": "Automatic bidding strategy is adjusting.",
  "BIDDING_STRATEGY_CONSTRAINED": "The campaign could convert more by adjusting the target CPA/ROAS.",
  "BUDGET_CONSTRAINED": "The budget limits the campaign's capability.",
  "BUDGET_MISCONFIGURED": "The budget is misconfigured.",
  "SEARCH_VOLUME_LIMITED": "The campaign does not target all queries.",
  "AD_GROUPS_PAUSED": "The ad group is paused.",
  "NO_AD_GROUPS": "No ad groups.",
  "KEYWORDS_PAUSED": "Keywords are paused.",
  "NO_KEYWORDS": "No keywords.",
  "AD_GROUP_ADS_PAUSED": "The ad group and ads are paused.",
  "NO_AD_GROUP_ADS": "No ad groups.",
  "HAS_ADS_LIMITED_BY_POLICY": "At least one ad in this campaign is limited by policy.",
  "HAS_ADS_DISAPPROVED": "At least one ad in this campaign is disapproved.",
  "MOST_ADS_UNDER_REVIEW": "Most ads are under review.",
  "MISSING_LEAD_FORM_EXTENSION": "The campaign has a lead form goal, and the lead form extension is missing.",
  "MISSING_CALL_EXTENSION": "The campaign has a call goal, and the call extension is missing.",
  "LEAD_FORM_EXTENSION_UNDER_REVIEW": "The lead form extension is under review.",
  "LEAD_FORM_EXTENSION_DISAPPROVED": "The lead extension is disapproved.",
  "CALL_EXTENSION_UNDER_REVIEW": "The call extension is under review.",
  "CALL_EXTENSION_DISAPPROVED": "The call extension is disapproved.",
  "NO_MOBILE_APPLICATION_AD_GROUP_CRITERIA": "No eligible mobile application ad group criteria exist in this campaign.",
  "CAMPAIGN_GROUP_PAUSED": "The user-specified campaign group status is paused.",
  "CAMPAIGN_GROUP_ALL_GROUP_BUDGETS_ENDED": "The user-specified times of all group budgets associated with the parent campaign group have passed.",
  "APP_NOT_RELEASED": "The app associated with this ACi campaign is not released in the target countries of the campaign.",
  "APP_PARTIALLY_RELEASED": "The app associated with this ACi campaign is partially released in the target countries of the campaign.",
  "need-subscription": "Feedcast Autopilot",
  "need-subscription-desc": "You need to subscribe to activate your campaign!",
  "products-success": "Promoted",
  "products-warning": "Warnings",
  "global-view-data": "Overview of Your Campaigns",
  "global-view-data-desc": "Connect your accounts and run campaigns for your products!",
  "assets": "Assets",
  "product_type": "Product Type",
  "add-marketing-images-portrait": "Add Marketing Images (Portrait)",
  "es_PA": "Spain & Panama / ES + PA",
  "da_DK": "Denmark / DK",
  "nb-products-selected": "{nb} product(s) selected",
  "config-merchant-address": "Configure Your Merchant Center Information!",
  "config-merchant-address-desc": "Just one more step! Fill in your company information to activate your brand new Merchant Center.",
  "add-shipping": "Add Shipping Fee",
  "know-more-about-projects": "Learn More About Creating Projects",
  "discover-performances-global-view": "Check out our article on the introduction to global view of performances",
  "more-about-products-performances": "Learn more about analyzing the performances of your products on Google Shopping",
  "edit-feed": "Edit My Shop",
  "how-to-connect-feed": "Check out our tutorial on how to connect a product catalog",
  "help-ads-account": "Learn how to create or connect an account",
  "help-csv": "Provide the link to your hosted product catalog in XML, CSV, or TXT format",
  "need-help": "Need Help?",
  "help-link-csv": "Check out our tutorial to help you connect your product catalog",
  "help-sheets": "Provide the link to your product catalog hosted on Google Sheets",
  "starter": "Autopilot",
  "premium": "Premium",
  "subscription-edit-success": "Successfully edited your subscription!",
  "access-platform": "Access to the platform",
  "product-handle": "Product Management",
  "optimize-feed": "Title & Description Enhancement",
  "follow-catalog-status": "Real-time Catalog Status Tracking",
  "categorisation": "Assisted Product Categorization",
  "handle-custom-labels": "Creation of Custom Labels",
  "feed-errors": "Detection of Feed Errors",
  "feed-correction": "Automatic Feed Error Correction",
  "personalized-reco": "Personalized Recommendations",
  "global-view-performances": "Overview of Advertising Performances",
  "reports-csv": "Detailed Reports & CSV Exports",
  "dashboard-checklist": "Dashboard and Personal Checklist",
  "performances-score": "Performance Score",
  "google-listing-free": "List your products for free on Google Shopping",
  "free-feedcast-shopping": "List your products for free on Feedcast.shopping",
  "google-campaigns": "Launch paid campaigns on Google",
  "meta-campaigns": "Launch paid campaigns on Meta",
  "amazon-campaigns": "Launch paid campaigns on Amazon",
  "microsoft-campaigns": "Launch paid campaigns on Microsoft",
  "chatbot": "Chatbot",
  "access-academy": "Access to the Knowledge Base (Academy)",
  "chat-support": "Chat Support on Weekdays from 10 AM to 6 PM",
  "phone-support": "Phone Support by Appointment",
  "campaign-setup": "Setup of Custom Campaigns",
  "new-functions": "Priority Access to New Features",
  "subscription-cancel-success": "Your subscription has been successfully canceled.",
  "fill-invoice-info": "Fill in Your Billing Information!",
  "fill-invoice-info-desc": "To activate a subscription on one of your feeds, we need your billing information.",
  "selection": "Selection",
  "missing-value-description": "Missing Product Description",
  "missing-value-description-todo": "Add a description to improve the quality of your product data",
  "low-image-quality": "Low-Quality Image",
  "low-image-quality-todo": "Add a higher-quality image",
  "invalid-value-availability": "Invalid value: Availability",
  "invalid-value-availability-todo": "invalid-value-availability-todo",
  "text-short-description": "Short description",
  "text-short-description-todo": "text-short-description-todo",
  "desktop-page-robots": "Unable to crawl desktop page due to robots.txt file",
  "desktop-page-robots-todo": "Update your robots.txt file to allow \"Googlebot\" and \"Googlebot-Image\" user-agents to crawl your site",
  "mobile-page-robots": "Unable to crawl mobile page due to robots.txt file",
  "mobile-page-robots-todo": "Update your robots.txt file to allow \"Googlebot\" and \"Googlebot-Image\" user-agents to crawl your site",
  "invalid-value-category-price": "Invalid value: Category price",
  "invalid-value-category-price-todo": "invalid-value-category-price-todo",
  "item-updates-price": "Automatically updates current prices.",
  "item-updates-price-todo": "No action required from your side",
  "invalid-value-gtin": "Invalid value: GTIN code.",
  "invalid-value-gtin-todo": "Add the valid worldwide GTIN code corresponding to your product",
  "incorrect-language": "Incorrect language",
  "incorrect-language-todo": "Specify an accepted content language for the target country.",
  "missing-value-image": "Missing product image.",
  "missing-value-image-todo": "Add an image to improve the quality of your product data.",
  "incorrect-value-identifier-exists": "Incorrect value: Identifier exists.",
  "incorrect-value-identifier-exists-todo": "This item has a unique international identifier. Send a valid GTIN code",
  "violation-google-policies": "Item disapproval due to policy violation",
  "violation-google-policies-todo": "Correct your items to comply with Google's policy",
  "missing-pricing-measure": "Missing unit pricing measure",
  "missing-pricing-measure-todo": "Add the unit pricing measure attribute [unit_pricing_measure] to comply with the regulations in this country.",
  "limited-performance-gtin": "Limited performance due to missing identifiers: GTIN [gtin], MPN [mpn], Brand [brand]",
  "limited-performances-gtin-todo": "Add a brand, and either a GTIN code or an MPN",
  "item-updates-availability": "Automatically updates available items by Google.",
  "item-updates-availability-todo": "No action required from your side",
  "unavailable-desktop": "Unavailable desktop landing page",
  "unavailable-desktop-todo": "Add a valid desktop redirect page",
  "promotional-overlay-image": "Promotional overlay image (image link)",
  "promotional-overlay-image-todo": "Add a valid image without any overlay elements",
  "excessive-capitalisation-title": "Excessive use of capitalization in your titles",
  "excessive-capitalisation-title-todo": "Remove excessive capitalization",
  "limited-performance-identifier-gtin-mpn-brand": "Limited performance due to missing identifiers: GTIN [gtin], MPN [mpn], Brand [brand]",
  "limited-performance-identifier-gtin-mpn-brand-todo": "Add a brand, and either a GTIN code or an MPN",
  "missing-value-size": "Missing value: Size",
  "missing-value-size-todo": "Add the size to your products if applicable",
  "missing-value-color": "Missing value: Color",
  "missing-value-color-todo": "Add the color to your products if applicable",
  "missing-value-gender": "Missing value: Gender",
  "missing-value-gender-todo": "Add the gender to your products if applicable",
  "missing-value-age-group": "Missing value: Age group",
  "missing-value-age-group-todo": "Add the age group to your products if applicable",
  "missing-energy-efficiency": "Missing energy efficiency attribute. This attribute is required for certain products in countries where regulations demand it.",
  "missing-energy-efficiency-todo": "Add the \"energy efficiency class\" attribute to your devices.",
  "invalid-characters-description": "Invalid characters in your description",
  "invalid-characters-description-todo": "Please correct the descriptions of the affected products",
  "ambiguous-value-gtin": "Ambiguous value for GTIN code",
  "ambiguous-value-gtin-todo": "Provide the full GTIN code, including leading zeros, and the full UPC, EAN, JAN, ISBN-13, or ITF-14 codes.",
  "unsupported-value-gtin": "GTIN code: Value not accepted due to forbidden prefix",
  "unsupported-value-gtin-todo": "Specify a GTIN code that is not within the restricted range (prefixes: 02, 04, or 2).",
  "invalid-value-ads-redirect": "Ads redirect: Invalid value",
  "invalid-value-ads-redirect-todo": "Use the correct ads redirect attribute [ads_redirect] to specify additional landing page settings on the product page you want to use for your Shopping ads",
  "missing-category-category": "Missing product description.",
  "missing-category-category-todo": "Add a description to improve the quality of your product data.",
  "audit-feed": "Product feed audit",
  "first-call": "First configuration call offered",
  "dashboard-page-title-desc": "This is where it all happens!",
  "feed-score": "FeedScore",
  "no-data": "No data!",
  "follow-personalized": "Personalized tracking",
  "male": "Male",
  "female": "Female",
  "unisex": "Unisex",
  "newborn": "Newborn",
  "infant": "Infant",
  "toddler": "Toddler",
  "kids": "Kids",
  "adult": "Adult",
  "no-feed-errors": "No feed errors!",
  "no-feed-errors-desc": "No feed errors have been detected on your product feed.",
  "no-feed-errors-desc-2": "When errors occur, you can fix them in bulk right from this space, without the need to go through each of your product listings!",
  "success-free-listing": "Success! Your product feed will be listed for free on Google Shopping.",
  "success-feedcast-comparator": "Success! Your product feed will be listed for free on our Feedcast.shopping listings.",
  "config-merchant-info": "Your account is verified! Just one more step...",
  "config-merchant-info-desc": "Fill in the billing information for your new Merchant Center account to finalize the activation. After that, you'll receive a notification from Google in your emails!",
  "config-merchant-phone": "Configure Your New Merchant Center Account!",
  "config-merchant-phone-desc": "We've created a brand new Merchant Center account for you. Use SMS verification to start running Google campaigns ⤵️",
  "edit-merchant-info": "Edit Your Merchant Center Information",
  "edit-merchant-info-desc": "Edit your contact details and provide your shipping fees, we'll take care of the rest!",
  "link-ads-account-desc": "Choose an advertising account from the list below to link it to your Feedcast shop.",
  "isUser": "Create a Feedcast Account",
  "isUser-desc": "Spoiler alert: it was the easiest part.",
  "hasShop": "Create Your First Shop",
  "hasShop-desc": "Spoiler alert: it wasn't too complicated either.",
  "hasAccount": "Add an Advertising Account",
  "hasAccount-desc": "Connect your existing account or create a new one with us.",
  "hasProducts": "Import Your Products",
  "hasProducts-desc": "Via a regular feed or from your shop.",
  "hasValidFeed": "Get a Validated Product Feed",
  "hasValidFeed-desc": "Essential to start running your campaigns.",
  "hasFreeDiffusion": "Activate Free Listing",
  "hasFreeDiffusion-desc": "The beginning of the adventure! We list your products for free!",
  "hasCampaign": "Launch Your First Campaign",
  "hasCampaign-desc": "Let's test the real distribution of your products now.",
  "hasDiffused": "Distribute for 14 Days",
  "hasDiffused-desc": "Collect data and go for your first sales.",
  "beginner": "Beginner",
  "confirmed": "Confirmed",
  "LOGIN": "Login",
  "feed_update": "Shop update",
  "campaign_update": "Campaign update",
  "subscription_update": "Subscription update",
  "campaign_create": "Campaign creation",
  "square": "Square",
  "portrait": "Portrait",
  "landscape": "Landscape",
  "unknown": "Unknown",
  "all-locations": "All countries",
  "all-locations-desc": "Distribute to all countries at once. No favorites!",
  "specific-locations": "Only selected countries",
  "specific-locations-desc": "Choose specific geographical areas.",
  // "targeting-parameters-desc": ""
  "error-oauth": "An error occurred while linking your account. Please try again later.",
  "subscription-restricted": "Start your Feedcast plan!",
  "subscription-restricted-desc": "Start your Feedcast subscription to create campaigns.",
  "configuration-call": "Configuration call",
  "sub-trial-unlocked": "30-day Autopilot trial",
  "hasConfig": "Configure a distribution channel",
  "hasConfig-desc": "Finish setting up at least one channel (Google or Meta)",

  // --------
  // "missing-age-group": "Add this attribute to your product data.",
  // "missing-availability": "Add this attribute to your product data.",
  // "missing-availability-date": "Add a value that complies with the product data specifications for this attribute.",

  "missing_item_attribute_for_product_type": "Missing attribute: {attribute}",
  "missing_item_attribute_for_product_type-desc": "Provide the {attribute} attribute in your product listings.",

  "item_missing_required_attribute": "Missing required attribute: {attribute}",
  "missing_related_attribute": "Missing attribute: {attribute}",
  "apparel_missing_brand": "Limited performance due to missing brand",
  "apparel_missing_brand-desc": "You must provide a valid brand for all products in the Apparel and Accessories category.",
  "dataquality_invalid_attribute_value": "Quality: Invalid value",
  "item_group_by_gtin_with_conflicting_attributes": "Conflicting values (GTIN, brand)",
  "google_category_unrecognized": "Unrecognized Google category",
  "coupon_gtin": "",

  "css_merchant_opt_out": "CSS not selected for this destination",
  "image_link_broken": "Invalid image link",

  "url_does_not_match_homepage": "Domains do not match",
  "url_does_not_match_homepage-desc": "Use landing page URLs that have the same domain as the website set in your Merchant Center account",

  "policy_violation": "Policy violation",
  "policy_violation-desc": "Your account has been suspended for policy violations.",

  "recalled_product": "Recalled product",
  "recalled-product-desc": "Follow the recall process implemented by the manufacturer or administrative authority.",

  "preemptive_item_disapproval_price_untrusted": "Inaccurate price due to inconsistencies between the feed and the landing page",
  "preemptive_item_disapproval_price_untrusted-desc": "Your account has received a warning, or your products have been disapproved as a precautionary measure due to this error.",

  "pending_initial_policy_review_shopping_ads": "Pending initial approval",
  "pending_initial_policy_review_shopping_ads-desc": "Please wait up to 3 business days for your catalog to be reviewed.",

  "shipping_weight": "Shipping Weight",

  "missing_shipping_weight": "Missing value: product weight",
  "missing_shipping_weight-desc": "Provide the weight of your product.",

  "missing_shipping": "Missing value: shipping cost",
  "missing_shipping-desc": "Provide the shipping costs for your products.",

  "missing_required_gtin": "Limited performance due to missing GTIN",
  "missing_required_gtin-desc": "Add the valid worldwide GTIN corresponding to your products.",

  "image_too_small": "Image too small",
  "image_too_small-desc": "Provide an image that is at least 100 x 100 pixels (non-apparel products) or 250 x 250 pixels (apparel products).",

  "image_single_color": "Single-color image",
  "image_single_color-desc": "Monochrome images can only be used for certain types of products.",

  "image_link_pending_crawl": "Image not fetched (slow crawling speed / pending crawl)",
  "image_link_pending_crawl-desc": "Ensure that Google can access your images and wait for the product image to be crawled (this can take up to three days).",

  "incorrect_identifier": "Incorrect GTIN identifier",
  "incorrect_identifier-desc": "Use the correct identifier for your products",

  "invalid_upc": "Invalid UPC",
  "invalid_upc-desc": "Add the valid worldwide GTIN corresponding to your product.",

  "item_duplicate_by_gtin": "Duplicate product code",
  "item_duplicate_by_gtin-desc": "Provide a unique product code for each product.",

  "image_unwanted_overlays": "Promotional overlay image",
  "image_unwanted_overlays-desc": "Edit the image of your products",

  "landing_page_crawling_not_allowed": "Unable to crawl desktop landing page due to robots.txt file",
  "landing_page_crawling_not_allowed-desc": "Update your robots.txt file to allow the user-agents \"Googlebot\" and \"Googlebot-Image\" to crawl your site",

  "mobile_landing_page_crawling_not_allowed": "Unable to crawl mobile landing page due to robots.txt file",
  "mobile_landing_page_crawling_not_allowed-desc": "Update your robots.txt file to allow the user-agents \"Googlebot\" and \"Googlebot-Image\" to crawl your site",

  "landing_page_error": "Desktop landing page unavailable",
  "landing_page_error-desc": "Update your store or landing page URL to allow access from desktops.",

  "mobile_landing_page_error": "Mobile landing page unavailable",
  "mobile_landing_page_error-desc": "Update your store or landing page URL to allow access from mobile devices.",

  "mismatching_brand_gtin_prefix": "Conflicting GTIN and brand",
  "mismatching_brand_gtin_prefix-desc": "Provide a valid worldwide GTIN associated with the brand you are submitting.",

  "price_mismatch": "Inconsistent value (page crawl): price [price]",
  "price_mismatch-desc": "Modify the product price in your product data to match the price displayed on your landing page.",

  "reserved_gtin": "Reserved GTIN",
  "reserved_gtin-desc": "Modify your unique GTIN codes.",

  "shipping_weight_too_high": "Item weight too high",
  "shipping_weight_too_high-desc": "The item weight must fall within the limits imposed by Google.",

  "account_disapproval": "Account suspended for quality issues",
  "account_disapproval-desc": "Address the quality issues with your account.",

  "identifier_exists_false_misuse": "GTIN identifier already exists",
  "identifier_exists_false_misuse-desc": "This item has a unique international identifier. Submit a valid GTIN code and set its value to \"true\" or remove this value.",

  "image_decoding_error": "Incorrect image encoding",
  "image_decoding_error-desc": "Check the validity of the image format and the accuracy of server settings.",

  "image_link_internal_error": "Failed to process image",
  "image_link_internal_error-desc": "Wait for the product image to be recrawled (up to three days)",

  "media_missing_gtin": "Limited performance due to missing identifiers: GTIN, manufacturer part number, or brand",
  "media_missing_gtin-desc": "Add a brand and a GTIN or manufacturer part number. If this product is one-of-a-kind or vintage, you do not need to add an identifier.",

  "color": "Color",

  "create-custom-label-desc": "Create a custom label on your items to include or exclude them from your Feedcast campaigns.",

  "no-categories-to-map": "Category Association Unavailable",
  "no-categories-to-map-desc": "Unable to find categories for matching with Google's classifications.",

  "excessive-capitalization-title": "Excessive use of capitalization in the title",
  "excessive-capitalization-title-todo": "Use less capitalization in your product titles.",

  "edit-custom-label-desc": "Edit your product label.",
  "image_link": "URL",
  "age group": "Age group",
  "gender": "Gender(s)",
  "price_out_of_range": "Product price exceeds limit",
  "price_out_of_range-desc": "Ensure that your product prices fall within the specified limit.",
  "product-has-identifier": "This product has a unique identifier",
  "success-multiple-edit": "Successfully edited your products!",
  "size": "Size",
  "size_k": "Size",
  "age_group_k": "Age group",
  "color_k": "Color",
  "gender_k": "Gender",
  "image_link_broken-desc": "The image link appears to be corrupted. Please provide a new image for your products.",
  "campaign-not-feedcast": "This campaign is linked to your Ads account but was not created from your Feedcast store. You can still edit its budget or enable/disable it!",
  "campaigns-feedcast": "Feedcast Campaigns",
  "your-shop": "Your Shop",
  "create-label": "Create a Dynamic Label",
  "cancel-selection": "Cancel my selection",
  "clear": "Reset",
  "identifier_exists": "Unique identifier exists",
  "brand_k": "Brand",
  "google_product_category_k": "Google Category",
  "availability_k": "Availability",
  "condition_k": "Condition",
  "invalid-password-confirm": "The passwords do not match.",
  "remember-password": "Remember your password?",
  "error": "Error",
  "warning": "Warning",
  "step-source": "Select your source",
  "step-credentials": "",
  // "update-subscription": "Upgrade my shop",
  "pay-today": "Pay Today",
  "amount-next-invoice": "Amount on Next Invoice",
  "downgrade": "Downgrade",
  "downgrade-desc": "You have chosen a lower subscription than the current one. Your current subscription will continue until the end of the already paid period, and then you will switch to your new subscription.",
  "cancel-sub": "Cancel Your Subscription",
  "cancel-sub-desc": "You are about to cancel your subscription. You will keep the current subscription until the end of the already paid period, and then switch to the free plan.",
  "your-selection": "Your Selection",
  "trial-30-days": "7-Days Free Trial",
  "start-sub": "Start My Subscription",
  "edit-sub": "Edit My Subscription",
  "cancel-sub-btn": "Cancel My Subscription",
  "accept-the": "I accept the",
  "sales-condition": "terms and conditions of sale",
  "recap": "Recap",
  "import-method": "Import Method",
  "language-country": "Country / Language",
  "shop": "Shop",
  "create-new-label": "Create a New Label",
  "filters": "Filters",
  "manual-list": "Add a Manual List",
  "copy-past-list": "Copy-paste your list here...",
  "select-field-to-edit": "Select the field to edit",
  "aggregation-id": "References",
  "aggregation-brand": "Brands",
  "aggregation-product_type": "Product Types",
  "aggregation-label0": "Label 1",
  "aggregation-label1": "Label 2",
  "aggregation-label2": "Label 3",
  "aggregation-label3": "Label 4",
  "aggregation-label4": "Label 5",
  "exclude": "Exclude",
  "include": "Include",
  "budget-quotidien": "Daily Budget",
  "asset-group-name": "Asset Group Name",
  "campaign-draft-delete": "Successfully deleted draft",
  "meta-advantage": "Meta Shopping Advantage+",
  "meta-advantage-desc": "Revolutionize your shopping campaigns with AI! Meta unveils a major innovation with Advantage+, ultra-targeted advertising campaigns for e-commerce and retail. Automation, relevance, and efficiency are on the agenda. Take advantage of this cutting-edge technology to boost your conversions and reach your customers like never before.",
  "ap-global": "Campaign",
  "facebook-page": "Facebook Page",
  "website-url": "Store URL",
  "data": "Data",
  "genre": "Genre",
  "behaviours": "Behaviors",
  "interests": "Interests",
  "last-days": "last day(s)",
  "from-the": "from the",
  "facebook": "Facebook",
  "audience_network": "Audience Network",
  "messenger": "Messenger",
  "instagram": "Instagram",
  "unpublished-draft-warning": "This campaign has an unpublished draft. If your campaign is active, the configuration being used is not that of your draft.",
  "draft-campaign": "New Draft Campaign",
  "draft-campaign-desc": "You have a new <b>campaign_type</b> campaign in draft. If you create and publish a new campaign, this draft will be overwritten.",
  "edit-campaign-draft": "Continue editing my draft",
  "delete-campaign-draft": "Delete my draft",
  "discover": "Discover",
  "how-to-campaign": "how to create a campaign",
  "different-campaign-types": "the different campaign types",
  "and": "and",
  "fc-warmup-1": "The Feedcast Warm-up campaign is a brand new concept: it allows you to easily and intelligently advertise your products across multiple channels (Google & Meta for now).",
  "fc-warmup-2": "It's almost automated! Feedcast Warm-up is the best type of campaign when you start promoting a shop: the more data you inject (rich catalog, brand creative elements, etc.), the more the algorithm will have room to test your ads across all networks and provide valuable learnings to optimize them!",
  "available-soon": "Coming soon!",
  "ad-group-name": "Ad Group",
  "ad-group-name-desc": "The name of the ad group that will be created when you create your campaign",
  "ip-address": "IP Address",
  "subscriptions-by-shops": "Your Subscriptions by Shop",
  "ap-global-desc": "Set the name and daily budget for your campaign.",
  "save-draft": "Save Draft",
  "delete-draft": "Delete Draft",
  "no-user": "Don't have an account?",
  "linking": "Linking in progress...",
  "canals": "Advertising Channels",
  "whats-next": "What's Next?",
  "no-custom-label": "No custom labels.",
  "filter": "Filter",
  "show-columns": "Displayed Columns",
  "warmup-google-desc": "Advertise your product campaigns across the entire Google network (Shopping, Search, Display, Discover, YouTube, Google Maps, Gmail...) and network partners.",
  "warmup-meta-desc": "Advertise your product campaigns across the entire Meta network (Facebook, Instagram) and network partners.",
  "warmup-amazon-desc": "Advertise your product campaigns across the entire Amazon network & partners.",
  "warmup-microsoft-desc": "Advertise your product campaigns across the entire Microsoft network & partners.",
  "diffuse-where": "Where do you want to advertise?",
  "budget-google": "Google Budget",
  "budget-meta": "Meta Budget",
  "logtype-121": "Product Feed Import",
  "logtype-3": "Issues accessing the channel",
  "ads-account-access-issue": "We can't access your channel. Please contact the Feedcast support.",
  "see-all-notifications": "See All Notifications",
  "warmup_campaign_status": "Warm-up Campaign",
  "success-warmup": "Your Warm-up is active on the {canal} channel! You can now edit this campaign.",
  "feed-import-sync": "Successfully synced your product catalog",
  "feedcast-trial": "Upgrade",
  "logo_square": "Logo (Square)",
  "marketing_landscape": "Marketing (Landscape)",
  "marketing_square": "Marketing (Square)",
  "notif-imported-products": "Feedcast Daily Import: {nb} products detected.",
  "feed-sync-loading": "Syncing product catalog to advertising channels#.",
  "no-notifications": "No new notifications!",
  "global-campaigns": "Overall Campaign Status",
  "diffuse-google-shopping": "Advertise your products for free on Google Shopping free listings. {br} This service is <b>mandatory</b> to send your product catalog in validation.",
  "feedcast-shopping-desc": "Advertise your products for free on the Feedcast.shopping online Marketplace.",
  "diffuse-free": "Free Channels",
  "success-clear-message": "The notification has been successfully cleared.",
  "account-linked": "Account Linked",
  "catalog-linked": "Catalog Linked",
  "link-catalog": "Set Up",
  "merchant-center-information": "Merchant Center",
  "configure-account": "Configure",
  "verify-account": "Verify My Account",
  "reward": "Reward: ",
  "begin-diffusion": "Start advertising your products to track your performance from this screen.",
  "product-feed": "Product Feed",
  "confirm-categories": "Confirm all categories automatically detected by Feedcast",
  "affiliate-code": "Affiliate Code",
  "feedscore-use": "What does my score mean?",
  "feedscore-use-desc": "Your score reflects the quality of your product feed. By following our recommendations and suggestions, improve this score to maximize the performance of your Feedcast campaigns!",
  "read-more": "Read More",
  "improve-score": "Improve My Score",
  "correct": "Correction",
  "show-concerned-products": "Show Concerned Products",
  "edit": "Edit",
  "product-waiting-validation": "correction(s) waiting for synchronization",
  "pending-validation": "Pending Synchronization",
  "title-from-feed": "Title (from your feed)",
  "success-batch-edit": "Correction saved",
  "validate": "Validate",
  "link-my-google-account": "Link my Google account",
  "link-existing-account": "Link existing account",
  "configure-m-center": "Configure Merchant Center",
  "configure-my-account": "Configure",
  "configure-catalog": "Configure",
  "link-meta": "Link Meta account",
  "create-new-account": "Create a new account",
  "import-my-products": "Import my products",
  "edit-filters": "Edit your search filters.",
  "once-analyzed": "Once analyzed, your product feed will receive a note based on its quality in order to improve it.",
  "all-your-projects": "All your projects",
  "price": "Price",
  "discount-price": "Discount price",
  "material": "Material",
  "age-group": "Age group",
  "sizes": "Size(s)",
  "weight": "Weight",
  "custom-label": "Custom label",
  "last-invoices": "Last invoices",
  "history": "Historic",
  "pick-plan": "Pick your plan",

  "free-1": "An <b>all-in-one dashboard</b>",
  "free-1-desc": "Track your unified performance across all channels from your dashboard: impressions, clicks, sales, revenue.",

  "free-2": "Import up to <b>1000 products</b>",
  "free-2-desc": "Connect your catalog and enrich your product feed data. Correct your feed errors, categorize products easily.",

  "free-3": "Broadcast on <b>free channels</b>",
  "free-3-desc": "Broadcast up to 1,000 products on Google Shopping's free listings and on our feedcast.shopping comparator",

  "free-4": "Link all your <b>ad accounts</b>",
  "free-4-desc": "Create/connect your advertising accounts in a few clicks to manage the visibility of your products in one place.",

  "free-5": "<b>Help Center</b> & <b>documentation</b>",
  "free-5-desc": "Access the numerous tutorials available on help.feedcast.io to learn about how Feedcast works.",

  "starter-1": "All the content of the <b>free plan</b>",
  "starter-1-desc": "Keep all the benefits of the Free plan. The Autopilot plan allows you to start running performance campaigns.",

  "starter-2": "Up to <b>1000 products</b> + options",
  "starter-2-desc": "Same quantity as in the free plan, these products can be broadcast in acquisition & retargeting campaigns.",

  "starter-3": "Broadcast <b>on 2 channels</b> + options",
  "starter-3-desc": "Choose up to 2 or more channels and launch campaigns to generate traffic & sales. +€25 per additional channel.",

  "starter-4": "Customize <b>your targeting</b>",
  "starter-4-desc": "With dynamic labels, customize the targeting & distribution of your products to optimize your performance.",

  "starter-5": "<b>Setup call</b> + standard support",
  "starter-5-desc": "Book your call with our team to set up your account together. Support available weekdays, from 10am to 6pm.",

  "premium-1": "All the content of the <b>Autopilot plan</b>",
  "premium-1-desc": "Keep all the benefits of the Autopilot plan. The Premium plan gives you access to an expert dedicated to monitoring your performance.",

  "premium-2": "<b>Unlimited products</b>",
  "premium-2-desc": "Import your entire product catalog without worrying about the number of products, as Premium you are no-limit!",

  "premium-3": "<b>Unlimited advertising channels</b>",
  "premium-3-desc": "Launch campaigns on as many channels as you wish: Google, Youtube, Facebook, Instagram, Microsoft...",

  "premium-4": "Your <b>dedicated expert</b>",
  "premium-4-desc": "Custom configuration of your campaigns. Up to 3 performance follow-up calls per month with your dedicated expert.",

  "premium-5": "<b>Premium priority support</b>",
  "premium-5-desc": "You get priority status on all your support requests, as well as access to all our upcoming features.",

  "actual-plan": "Current plan",
  "need-more-products": "Need more products ? (optional)",
  "optimise-products": "Optimise products",
  "monitor-performances": "Monitor Performances",
  "diffuse-products": "Diffuse your products",
  "be-followed": "Have support",
  "noob": "Starting",
  "noob-expert": "Starting & Expert",
  "success-edit-product": "Saved changes",
  "or": "Or",
  "pass-step": "Pass this step",
  "presentation": "Presentation of the ",
  "products-global-view": "products' global view",
  "google-statuses": "the details of statuses",
  "find-how": "Discover how",
  "edit-products": "edit your catalog",
  "and-our": "and our",
  "feed-advices": "optimisation advices",
  "connect-catalog-begin": "Connect your catalog to begin.",
  "no-campaign-filters": "You have no campaigns with these filters.",
  "french": "French",
  "english": "English",

  "logtype-google-merchant": "Google Merchant Center Alert",
  "logtype-1": "Feed synchronization error",
  "feed-download-error": "There seems to be a problem with the synchronization of your feed with our service.",
  "logtype-MISSING_FEED": "No products detected",
  "missing-import-method": "Your store does not have any import method configured. Add an import method for your product catalog to start using Feedcast!",

  "logtype-125": "Limited Campaigns",
  "limited-campaigns-desc": "There seems to be one or multiple campaigns limited.",
  "logtype-123": "Account payment missing",
  "account_payment_missing-desc": "Please fill your invoice data.",
  "logtype-124": "No active campaigns",
  "no_active_campaigns-desc": "You don't have any active campaigns on that shop.",
  "logtype-122": "Data loss",
  "feed_import_drop-desc": "We detected a drop in your products' volume. Previously : {previous} products. Currently : {current} products.",
  "logtype-120": "Synchronized feed",
  "logtype-129": "No products !",
  "logtype-130": "Feed error",
  "logtype-131": "Merchant Center",
  "errors": "Errors",
  "batch-attribute-not-handled": "Attribute not handled",
  "batch-attribute-not-handled-desc": "The correction of the attribute is not yet handled by Feedcast. Please do contact the support team if you need any help.",
  "load-more": "Load more",
  "show-data": "Show data",
  "pressing-yes-will": "Pressing 'Yes' will change the ",
  "are-you-sure": "Are you sure ?",
  "pendings": "Pending",
  "identifier-exists": "Unique Identifier",
  "link-existing-google-account": "I want to link an existing Google Ads account",
  "dsa-beneficiary": "DSA Beneficiary",
  "dsa-payor": "DSA Payor",
  "will-be-affected": "{products} products will be affected by your action.",
  "project-use": "A project allows you to regroup multiple shops under a single entity.",
  "vat_number": "VAT Number",
  "data-cards": "Here is shown the main metrics of your campaigns.",
  "feed-score-tour": "The Feedscore gives you a quick glance at the quality of your product feed.",
  "tasks-tour": "Your task list shows you what you should do next in Feedcast.",
  "ads-accounts-dashboard": "You can direclty connect your advertisement accounts in a few click from here.",
  "app-nav-tour": "This is the app navigation. You can use it to access the different pages of the app.",
  "page-dashboard-tour": "The Dashboard page shows a global state of your shop status, and what to do next.",
  "page-performances-tour": "The Performances page allows you to consult your campaign's data.",
  "page-products-tour": "The Products page allows you to edit, correct and enhance your product feed.",
  "page-campaigns-tour": "The Campaigns page allows you to create, edit and monitor your campaigns.",
  "project-selector-tour": "You can easily navigate from one shop to another by using the Shop Selector.",
  "app-notifications-tour": "Easily check important informations with the app notifications.",
  "app-settings-tour": "Consult your settings, logout or change your language.",
  "datepicker-tour": "Define a date range for your data. By default, the last 7 days are selected.",
  "perf-chart-tour": "Easily compare your campaign's data with the Performance Chart.",
  "catalog-status-tour": "Quickly overview the statuses of your products: validated, pending, warnings, errors.",
  "devices-distribution-tour": "The repartition of the devices people use to consult your products.",
  "accounts-metrics-tour": "Summarized campaign data of your different channels.",
  "product-reporting-data-tour": "The detailed reporting of your products on the Google channel.",
  "product-reporting-filter-tour": "You can filter the results by defining ranges.",
  "campaigns-data-cards-tour": "Quickly view how well your campaigns are performing.",
  "free-diffusion-tour": "Once you met the requirements, you can activate the free diffusion of your products on the specified channels.",
  "ads-accounts-tour": "Overview your advertisement channels and configure them.",
  "project-tour": "You can regroup multiple shops into a single project.",
  "project-shop-tour": "You can access to your shop by clicking on it, or move it to another project by drag and drop.",
  "project-button-tour": "Add a new shop to your project, or rename the project",
  "select-products-reporting-tour": "You can then select your products, and create custom labels like \"Best sales\", or \"Top Summer Products\"",
  "products-data-cards-tour": "A quick view of your synchronized products.",
  "no-products-tour": "It seems you don't have any products yet. Once imported, you'll see the historic of your catalog status.",
  "products-history-tour": "The history of your catalog status from the last 10 days.",
  "products-doughnut-tour": "Another view of your catalog status. You can use the date navigator to consult it through your history.",
  "product-filters-tour": "Filter your products with keywords, or with their status : all, active, pending, warnings or errors.",
  "select-products-list": "You can then select your products to edit multiple products at once, or add them to a custom label.",
  "edit-product-tour": "Edit your product to enhance its data, or to correct warnings and errors.",
  "product-feed-tour": "Edit your shop's name, subscription or your feed URL from here.",
  "feed-errors-tour": "Your products' warnings and errors are displayed here and gives you either a quick selection of said products, or a direct way to correct them.",
  "product-categories-map-tour": "By default, Feedcast tries to associate your categories with Google categories. You can either confirm the associations, or edit them.",
  "cancel-subscription-confirm": "Are you sure you want to cancel your subscription ?",
  "confirm-cancel": "Confirm cancel",
  "account-name": "Account name",
  "new-code": "New code",
  "ship-value": "Shipment #{i}",
  "leave-blank-for-max": "Leave empty for upper price",
  "finish-config": "Finish the configuration",
  "fetching-pixels": "Fetching your pixels...",
  "create-first-project": "Create your first project",
  "setup-credentials": "Configure your feed",
  "your-information": "Your invoice information",
  "success-draft": "Your draft has been successfully saved.",
  "draft": "Draft",
  "published": "Published",
  "chars-max": "characters maximum",
  "publish": "Publish",
  "saved": "Saved",
  "inactive-campaign": "Inactive campaign",
  "active-campaign": "Active campaign",
  "inactive-campaign-tooltip": "You can enable your campaign directly from the list",
  "active-campaign-tooltip": "This campaign is currently online",
  "has-draft-tooltip": "This campaign is a draft, and is saved locally on your computer. Click on \"Save\" to save your changes.",
  "saved-tooltip": "This campaign is saved on the server.",
  "one-shipping-minimum": "You don't have any shipping cost in your Merchant Center.",
  "merchant-cache": "Google's services are currently under maintenance. It might take up to 5 minutes to update your data.",
  "missing-configuration": "To create this campaign type, you need to enable the corresponding channel in the Configuration page.",
  "subscribe": "Subscribe",
  "success-free-listing-inactive": "Your product feed has successfully been removed from the Google Free Listing.",
  "success-feedcast-comparator-inactive": "Your product feed has successfully been removed from the Feedcast Shopping comparator. Beware, it can take up to two business days for your products to be fully removed.",
  "google-listing-requirements": "To activate the Google Free Listing, you must configure your Google Ads account, have a valid product feed and at least one product in Feedcast.",
  "feedcast-shopping-requirements": "To activate the Feedcast Shopping comparator, you must have a valid product feed and at least one product in Feedcast.",
  "image-format": "Image format",
  "supported-formats": "Handled formats: PNG, JPG",
  "missing": "You're missing : ",
  "back": "Back",
  "config-call": "Configuration call",
  "newfeed-csv-tuto-1": "To use this format, you must already have a file hosted on a URL.",
  "newfeed-csv-tuto-2": "Once you have created or retrieved your product feed, all you need to do is add it to your Feedcast account.",
  "newfeed-csv-tuto-3": "The accepted formats are as follows: CSV, XML, TXT.",
  "newfeed-csv-tuto-4": "Caution: Also remember to update your catalog in case of changes (price, stock status, adding new products etc.). This can impact the validation of your product catalog and the performance of your campaigns.",
  "more-info": "Find more information on our",
  "help-center": "help center",
  "newfeed-sheets-tuto-1": "To use this format, you must already have a Google Sheet file containing the information related to your products with the correct data formatting.",
  "newfeed-sheets-tuto-2": "If you don't have one, here is an example of a duplicable Google Sheet document: ",
  "google-support": "Google support",
  "newfeed-sheets-tuto-3": "This file format is recommended for catalogs with few products and which have infrequent updates (price, stock, new products..).",
  "newfeed-sheets-tuto-4": "Also remember to update your catalog in case of changes (price, stock status, adding new products etc.). This can impact the validation of your product catalog and the performance of your campaigns.",
  "more-info-google": "You can also find more information on the",
  "dash": "Dashboard",
  "start-on-feedcast": "How to start on Feedcast",
  "feed-onboarding": "Connect your product feed 🛍️",
  "feed-onboarding-desc": "The product feed is the foundation. Once connected, your store will be synchronized with Feedcast, and you can start using the tools to improve the quality of your feed!",
  "ads-onboarding": "Add an Ads account ⚡️",
  "ads-onboarding-desc": "You can easily link an existing advertising account or even create a new one with us and enjoy a 30% discount thanks to our Google CSS Partner status!",
  "free-onboarding": "Activate free broadcasting 📈",
  "free-onboarding-desc": "You can already broadcast your products for free on Google Listing and also on our online comparator Feedcast Shopping!",
  "campaign-onboarding": "Launch your first campaign 🚀",
  "campaign-onboarding-desc": "Ready to boost your sales? Launch your first campaign on Feedcast!",
  "dialog-feed-onboarding": "Adding your product catalog 🛍️",
  "import-products-pending": "Your products are being imported...",
  "ads-configured": "Active channel",
  "import-feed-loading": "Importing your catalog...",
  "feed-parameters": "Settings",
  "add-source": "To unlock this feature, add a product feed!",
  "delete-label": "Delete label",
  "edit-product-feed": "Edit my product feed",
  "link-product-feed": "Connect my product feed",
  "diffuse-my-products": "Broadcast my products",
  "launch-campaign": "Launch my campaign",
  "unlock-trial": "Trial version unlocked!",
  "unlock-configuration-call": "Unlock your onboarding call",
  "unlock-trial-todo": "Unlock your 30-days free tiral!",
  "unlock-configuration-call-todo": "Unlock your configuration call!",
  "free-desc": "Everything you need to broadcast for free and enhance your stream.",
  "starter-desc": "It's getting serious: tools to broadcast your products on a large scale.",
  "premium-desc": "Be supported at every moment of your Feedcast journey!",
  "new-plan": "New",
  "sorry-to-see-you-go": "We're sorry to see you go 😟",
  "help-us-improve": "Before saying goodbye, could you tell us why?",
  "CANCEL-HARD_TO_USE": "Too complicated to use",
  "CANCEL-NOT_ENOUGH_TIME": "I don't have enough time to manage it",
  "CANCEL-INVALID_CATALOG": "Invalid catalog",
  "CANCEL-PERF_ISSUES": "Due to performance / ROI issues",
  "CANCEL-UNKNOWN": "Something else (please specify)",
  "where-do-you-fail": "Where is it failing?",
  "consider-premium": "Have you considered the premium offering?",
  "do-you-diffuse": "Have you been broadcasting for more than 30 days?",
  "precise": "Specify:",
  "asset-group-name-helper": "The asset group includes the titles, descriptions, and images related to your campaign.",
  "perfmax-pictures": "Images of your campaign",
  "caution-cancel": "Caution!",
  "caution-cancel-desc": "If you cancel your subscription, all your campaigns and their data will be permanently deleted.",
  "caution-cancel-desc-2": "Are you sure you want to cancel your subscription ?",
  "x-campaigns": "You currently have {nb} campaigns linked to this shop.",
  "please-explain": "Explain to us...",
  "yes-cancel": "Yes, cancel my subscription",
  "cancel-sub-1": "Attention: By cancelling your subscription, all your campaigns created via Feedcast will be deleted. You will lose the history, progress, and data of your campaigns without the possibility of recovery.",
  "cancel-sub-2": "If you've created a Google Ads account via Feedcast, that account remains your property. However, you will lose access to the Google Merchant Center account, as it is associated with the Feedcast CSS.",
  "talk-to-expert": "Talk to an expert",
  "about-to-cancel": "You are about to unsubscribe",
  "scheduled-edits": "Scheduled Edits",
  "next-edits": "The following edits will take effect starting from the next billing cycle:",
  "cancel-edits": "Cancel my edits",
  "cancel-sub-will-do": "Cancelling your subscription will result in:",
  "logout-feedcast-feed": "Logging out of the Feedcast-optimized feed",
  "campaigns-deleted": "Deletion of your campaigns",
  "campaigns-historic-deleted": "Loss of historical data related to your campaigns",
  "css-advantages-loss": "Loss of Google CSS Partner benefits (20% on Google Shopping bids, eligible countries)",
  "configure-canal": "Configure canal",
  "init-google-canal": "Activate your Google channel",
  "select-account-to-link": "Select the account to link",
  "create-google-ads": "Create a Google Ads account",
  "link-existing-account-desc": "Connect one of your advertising accounts to manage it from Feedcast!",
  "google-offer": "€400 of free advertising budget!",
  "google-discount": "20% discount",
  "on-google-shopping": "on your Google Shopping bids",
  "create-account-advantages": "Thanks to Feedcast's Google CSS Partner status, you can enjoy €400 of free advertising budget and a 20% discount on your Google Shopping bids (eligible countries).",
  "create-account-advantages-2": "To learn more about the conditions for obtaining these benefits, visit ",
  "helpdesk-article": "the help center",
  "get-most-of-fc": "Feedcast CSS Partner",
  "account-not-in-list": "My account is not on the list",
  "coupon-code": "Coupon code",
  "get-my-offer": "Get my offer",
  "your-subscription": "Your subscription",
  "promotion-desc": "Get your first 2 month at 29€ excl. taxes / month !",
  "m-center-desc": "Here, you can edit your Merchant Center information and provide your delivery costs.",
  "delivery-help": "Minimum / maximum delivery time for your products (in days)",
  "handling-help": "Minimum / maximum handling time for orders (in days)",
  "rates": "Rates",
  "max-hour-help": "Maximum hour for handling orders",
  "edit-import-method": "Edit import method",
  "no-microsoft-accounts": "No Merchant Center account(s) detected",
  "products-catalog": "Products Catalog",
  "feed-configuration": "Settings",
  "diffusion": "Advertising",
  "metrics": "Metrics",
  "product-performances": "Reporting",
  "edit-shop-name": "Edit shop name",
  "welcome-feedcast": "Welcome to Feedcast.",
  "welcome-feedcast-desc": "Before we get down to business, we'd like to know a bit more about you!",
  "shop-url": "Store URL",
  "global-performances": "Overview",
  "campaigns-handle": "Campaigns",
  "diffusion-configuration": "Diffusion",
  "feed-optimisation": "Optimization",
  "products-state": "Product Status",
  "delete-campaign": "Delete campaign",
  "delete-campaign-confirm": "This action will permanently delete this campaign. We'll keep all the data until today. If you want to proceed, please write <b>campaign</b> in the text field just below.",
  "yes-delete-campaign": "Yes, permanently delete",
  "success-delete-campaign": "Your campaign has been deleted.",
  "microsoft-shopping": "Microsoft Smart Shopping",
  "microsoft-shopping-desc": "Maximize your performance by using automatic bidding and precise targeting on the Microsoft Search Network. Smart Shopping campaigns take priority over standard Shopping campaigns for the same targeted products",
  "campaign-desc": "Provide campaign information",
  "delete-feed": "Delete shop",

  "cancel-import-method": "Cancel",
  "subscribe-desc": "This feature is reserved for subscribers. To access it, choose a plan for your store.",
  "begin-subscription": "See plans",
  "bidding-strategy": "Bidding Strategy",
  "saving-campaign": "Saving your campaign data...",
  "campaign-behaviour": "Campaign Behaviour",
  "all-that-have-not": "All the people that have not ",
  "all-that-have": "All the people that have ",
  "delete-feed-confirm": "If you decide to delete your shop, everything that is related to it will be permanently destroyed. To proceed, please write <b>url</b> in the field below.",
  "confirm-delete": "Yes, delete my shop forever",
  "success-delete-feed": "Your shop has correctly been deleted.",
  "microsoft-center-name": "The name of your new Merchant Center",
  "domain-name": "Domain name",
  "create": "Create",
  "your-domain-name": "Your shop's domain",
  "link-existing-merchant-center": "Link existing Merchant Center",

  "create-microsoft-center": "Create a new Merchant Center",
  "microsoft-center-requirement": "Your domain name must be verified with Microsoft",
  "link-existing-merchant": "Link an existing Merchant Center",
  "link-existing-merchant-desc": "Access the list of your Merchant Centers",
  "delete-rule": "Delete rule",
  "create-a-label": "Create a label",
  "source-origin": "Source Feed",
  "source-feedcast": "Feedcast Feed",
  "previous-product": "Previous product",
  "next-product": "Next product",
  "visit-product": "Visit product",
  "to-delete-need-free": "To delete your shop, you need to cancel your subscription and wait until your shop gets back to the Free plan.",
  "success": "Success",
  "bg": "Bing",
  "google-product-reporting": "Google Product reporting",
  "cant-sub": "Invoice information missing",
  "cant-sub-desc": "To subscribe, you must first fill out your billing information <a>on this page!</a>",
  "success-channel-toggle-true": "The broadcast channel has been successfully activated!",
  "success-channel-toggle-false": "The broadcast channel has been successfully deactivated.",
  "toggle-channel-true": "Activate channel {company}",
  "toggle-channel-true-desc": "Do you wish to activate channel {company} on this store?",
  "toggle-channel-false": "Deactivate channel {company}",
  "toggle-channel-false-desc": "Do you wish to deactivate channel {company} on this store? Please note that deactivating a channel will have the following consequences:",
  "channel-to-be-true": "Activate channel",
  "channel-to-be-false": "Deactivate channel",
  "additional-platforms": "Broadcast Channels",
  "nb-platforms": "{nb} channels",
  "too-many-platforms": "To perform this operation, you must deactivate at least one of the following broadcast channels:",
  "platform-remaining": "{nb} additional channel available",
  "platforms-remaining": "{nb} additional channels available",
  "product-catalog": "Product Catalog",
  "no_active_campaigns": "No Active Campaigns",
  "account_payment_missing": "Billing Data Missing",
  "feed_sync_end": "Catalog Synchronization",
  "no-error": "No Error",
  "success-creation-microsoft-center": "Your new Merchant Center has been successfully activated!",
  "next-removed-platforms": "Deactivation of Advertising Channels",
  "next-removed-platforms-desc": "The following advertising channels will be <b>deactivated</b>:",
  "cancel": "Cancel",
  "informations": "Informations",
  "search": "Search",
  "search-products-placeholder": "Reference, title, brand...",
  "GOOGLE_PERFORMANCE_MAX": "Google Performance Max",
  "META_SHOPPING_ADVANTAGE": "Meta Shopping Advantage+",
  "GOOGLE_SHOPPING_CLASSIC": "Google Shopping Classic",
  "countries": "Countries",
  "languages": "Languages",
  "all-campaign-products": "All my products",
  "nb-products": "{nb} products",
  "catalog-errors-recap-desc": "Review and address these issues from your connected advertising channels to enhance performance",
  "free-listing": "Free Listing",
  "categories-desc": "Enhance your visibility by mapping your custom categories to Google's standard classifications",
  "categories-explanation": "If categories are detected in your feed, Feedcast tries to match them with existing Google categories. It's up to you to confirm this automatic link, or to specify another one! ⬇️",
  "search-for-existing-label": "Search for existing label",
  "no-source": "Custom Labels disabled",
  "no-source-desc": "Add a product feed to enable custom label creation for your shop.",
  "shipping-weight": "Shipping Weight",
  "target-roas-disabled-when-new": "The target ROAS is disabled for new campaigns.",
  "alerts-merchant-center": "Merchant Center Alerts",
  "more-details": "More details",
  "import-product-catalog": "Import Product Catalog",
  "enable-free-listing": "Enable Free Shopping Listing",
  "choose-plan": "Subscription Management",
  "channels": "Channels : {channels}",
  "enable-channel": "Enable Channel",
  "diffusion-shopping": "Diffusion Shopping",
  "all": "All",
  "feedcast-mobile": "This web application is not usable on mobile devices.",
  "init-channel": "Initialise channel configuration",
  "success-revoke-channel": "Your channel has successfully been revoked. ",
  "sync-feed": "Sync my feed",
  "feature-not-available": "Feature not available",
  "are-you-sure-sync-feed": "By activating this option, you will synchronize your product feed with the selected distribution channel. {br}<b>Note that this action is limited to once per day.</b> {br} We recommend using it mainly to update your product catalog after correcting any errors in your feed.",
  "confirm": "Confirm",
  "success-sync-feed": "Your product feed has been successfully sent for synchronization!",
  "siret": "SIRET",
  "vat-exempted": "VAT exempted",
  "accounts": "Applications",
  "last-check": "Last check",
  "uninstall-app": "Uninstall",
  "sure-revoke": "Uninstall application",
  "sure-revoke-desc": "Are you sure you want to reset the connection with this channel?",
  "confirm-revoke": "Yes, uninstall",
  "channel": "Channel",
  "feedback-desc": "An issue, a suggestion? Give us your feedback! 🚀",
  "title-feedback-desc": "A brief title of your issue / suggestion",
  "f-feedback-desc": "A brief description of your issue / suggestion",
  "severity": "Severity",
  "submit": "Submit",
  "thank-you-feedback": "Thank you for your feedback! ✨",
  "merge-duplicates": "Merge duplicates",
  "invalid-request": "Invalid request.",
  "error-auth": "Authentication failure",
  "unauthorized": "Access to an unauthorized resource",
  "unknown-resource": "Access to an unknown resource",
  "limit-per-day": "This request is limited to one use per day.",
  "backend-error": "Server error",
  "unknown-backend-error": "Unknown server error",
  "bad-credentials": "Invalid credentials",
  "cant-subscribe": "Access denied",
  "cant-subscribe-desc": "You are not authorized to manage your stores' subscriptions from Feedcast. {br} If you are a Feedcast Partner, subscription management is done directly from your Partner space. {br} Otherwise, please contact Feedcast support.",
  "init-channel-desc": "This action will result in a complete reset of the configuration of the selected channel. Are you sure you want to continue?",
  "crop-image": "Crop image",
  "crop": "Crop",
  "columns": "Columns",
  "success-init-channel": "The channel has successfully been initialized.",
  "serivice-enabled": "Service enabled",
  "title-or-ref": "Product title or reference",
  "hide-products-not-found": "Hide products that aren't in my current catalog",
  "see-full-product": "Product sheet",
  "preview-product": "Preview product",
  "no-access-to-ads-account": "Issue with account access. Please contact the Feedcast support.",
  "asset-manager": "Asset Manager",
  "no-assets": "No assets!",
  "no-assets-desc": "Import your first asset.",
  "assets-marketing-explained": "<b>Marketing</b>: A creative image that highlights your brand or products",
  "assets-logo-explained": "<b>Logo</b>: The logo of your brand.",

  "handle-dynamic-label": "Handle dynamic labels",
  "stripe-edit": "Subscriptions",
  "stripe-edit-card": "Update payment method",
  "my-product-feed": "My product feed",
  "catalog-state-nav": "Catalog status",
  "handle-free-listing": "Handle Free Listing",
  "free-listing-on": "Activate Free Listing",
  "free-listing-off": "Deactivate Free Listing",
  "activate-channel-from-service": "To activate this service, your advertising channel must also be activated! 👨‍🏫",
  "setup-channel": "Set up channel",
  "paid-feature": "Reserved for subscribers",
  "feedcast-marketplace-desc": "Free distribution of your products on Feedcast.shopping listings",
  "free-listing-desc": "Free distribution of your products on Google Free Listing listings",
  "visit-feedcast-shopping": "Visit feedcast.shopping",
  "warning-issued": "Product warning",
  "channels-limit": "Advertising channels limit reached 👨‍💻",
  "channels-limit-desc": "You have reached the limit of advertising channels for the subscription you have chosen.",
  "channels-limit-desc-2": "To be able to activate this additional channel, we invite you to consult the different options available on the subscriptions page!",
  "configured-but-not-active": "The channel is configured but is not active! <a>Activate my channel</a>",
  "no-active-channels": "You don't have any active channels",
  "no-active-channels-desc": "You have at least one channel fully configured, but no channel is active on your shop. Click on one of the switch below to activate one ! ⬇️",
  "image-too-small": "The image is too small for at least one of the selected option.",
  "adult_k": "Adult product",
  "is_bundle": "The product is a bundle of products",
  "create-microsoft-center-alert": "Creating a Merchant Center account - Microsoft",
  "create-microsoft-center-alert-desc": "In order to create a Merchant Center account from Feedcast, your <b>domain name must be verified with Microsoft.</b>",
  "create-microsoft-center-alert-desc-2": "Make sure you have validated your domain name from the Bing console.",
  "already-have-m-merchant": "I already have a Microsoft Merchant Center",
  "need-to-check-domain": "I need to verify my domain name",
  "have-checked-domain": "I have a verified domain name",
  "be-sure-checked": "Make sure you have verified your domain name from the Bing console.",
  "helper-microsoft-center-domain": "The domain name must be exactly the one verified with Microsoft.",
  "free-field": "For your information, you can choose whatever name you want.",
  "all-genders": "All genders",
  "HAS_ASSET_GROUPS_LIMITED_BY_POLICY": "Your campaign is limited due to some errors in your asset group.",
  "headline": "Short title ",
  "long-headline": "Long title ",
  "description-short": "Short description ",
  "description-long": "Long description ",
  "linked-channels": "Linked channels",
  "pending-user-validation": "Pending validation",
  "pending-user-validation-desc": "Your account is pending validation.",
  "no-conversions": "No conversions detected",
  "has-conversions": "Conversions detected",
  "no-pixels-detected": "No conversion pixel detected",
  "no-unique-identifier": "No unique identifier",

  "blocking": "BLOCKING",
  "non-blocking": "NON-BLOCKING",
  "UNACCEPTABLE_SPACING": "Unacceptable spacing",
  "DISCOVERY_AD_REQUIREMENTS_UNCLEAR_IMAGE": "This image may be confusing.",
  "RELIGIOUS_BELIEF_IN_PERSONALIZED_ADS": "Inappropriate use of religious beliefs in personalized ads.",
  "images": "Images",
  "texts": "Texts",
  "add-video": "Add a video",
  "videos": "Videos",
  "assets-pictures-warnings": "Some of your images are problematic.",
  "assets-pictures-warnings-desc": "The following images have been identified as problematic:",
  "assets-issues": "Campaign warnings",
  "assets-texts-warnings": "Some of your texts are problematic.",
  "assets-texts-warnings-desc": "Please correct the texts concerned.",

  "alert-stripe-link": "Missing billing informations",
  "alert-stripe-link-desc": "We're missing your billing information to link your profile with our payment partner, Stripe. Please fill in the missing details to subscribe your Feedcast account.",
  "available-options": `{nb} available option(s)`,
  "last-import-date": "Last product import in Feedcast: {date}",
  "is-blocking": "Is blocking",
  "warnings-errors-detail": "Warning / Error",
  "no-history": "No history !",
  "historic": "History",
  // "whats-next": "What's next?",
  "lets-go": "Let's go",
  "ads-spent": "Advertising expenses",
  "payload-too-large": "The payload is too large.",
  "woocommerce-limited-preview": "Woocommerce: Limited Preview to 500 products",
  "woocommerce-limited-preview-desc": "Woocommerce services are quite slow! Feedcast limits the preview to avoid blocking you.",
  "feedscore": "FeedScore",
  "ai-generation-error": "An error has occurred during generation.",
  "feed-limit-reached": "Feed limit reached. Contact the support team to get more.",
  "email-language": "Email Languages",
  "ht-first-month": "First month at",
}

const projects = {
  "go-to-settings": "Go to your account settings",
  "helpdesk-desc": "Our Feedcast help center!",
  "app-mode": "Mode",
  "app-mode-desc": "Prefer light or dark?",

}

const notifications = {
  "no-history": "No history!",
  "historic": "History",
  "click-for-more": "Click to fix",
}


const pageInfo = {
  "your-product-reporting": "Your product performance",
  "page-info-product-reporting": "This report presents detailed results by product for ads displayed in Google Shopping locations (<a>learn more about ad display on Google</a>)",
  "your-custom-labels": "Your custom labels",
  "page-info-custom-labels": "Segment your products with custom labels and broadcast your chosen products in your campaigns. (<a>Learn more about custom labels</a>)",
  "your-optimisation": "Optimize your product feed",
  "page-info-optimisation": "Optimize your catalog for better performance in your campaigns! (<a>Find our advice on the help center</a>)",
  "page-info-product-list": "Optimize your product catalog for better performance in your campaigns. (<a>Find our advice on the help center</a>)",
  "your-product-edit": "Optimize your product details",
  "page-info-product-edit": "Optimize your product details for better performance on your campaigns! <a>Find our advice on the help center</a>"
}

const login = {
  "diffuse-easily": "Easily broadcast your products online",
  "optimise-catalog": "Optimise your product catalogue with AI",
  "20-percent-cpc": "Save 20% on your Google Shopping CPC",
  "google-ads-credit": "€400 of Google Ads credit offered",
  "free-trial-7days": "Test for free for 7 days",
  "login": "Login",
  "comeback": "We're thrilled to see you again, but you still have to log in!",
  "continue": "Continue",
  "no-account": "Don't have an account?",
  "register": "Register",
  "already-have-account": "I already have an account",
  "your-name": "Your company",
  "your-email": "Your email",
  "password": "Password",
  "confirmation": "Confirmation",
  "forgotten-password": "Forgotten password?",
  "password-requirements": "Your password contains:",
  "register-catch": "It's quick, it's free, and it's essential to go further",
  "password-minimum-length": "At least 6 characters",
  "password-lowercase": "Lowercase letters (a-z)",
  "password-uppercase": "Uppercase letters (A-Z)",
  "password-numbers": "Numbers (0-9)",
  "refresh": "Forgotten password?",
  "login-desc": "Log in to access your dashboard!",
  "register-desc": "Create your account in a few clicks",
  "register-desc-2": "Or sign up with email:",
  "back-to-website": "Back to the website",
  "handle-campaigns-everywhere": "Manage your e-commerce campaigns everywhere",
  "sign-in-google": "Sign in with Google",
  "remember-password": "I remember my password!",
  "refresh-desc": "Don't panic, enter your email to reset it:",
  "refresh-password": "Reset my password",
  "refresh-password-success": "Check your inbox!",
  "register-conditions": "By continuing, you agree to the Feedcast Terms of Use. <a>Read our terms and conditions</a>",
}

const logtypes = {
  "logtype-google-merchant": "Google Merchant Center Alert",
  "logtype-1": "Feed synchronization error",
  "feed-download-error": "There seems to be a problem with the synchronization of your feed with our service.",
  "logtype-MISSING_FEED": "No product detected",
  "missing-import-method": "Your store has no configured import method. Add an import method for your product catalog to start using Feedcast!",
  "logtype-125": "Limited campaign(s)",
  "limited-campaigns-desc": "One or more of your campaigns are limited.",
  "logtype-123": "No billing data",
  "account_payment_missing-desc": "Please enter your billing information.",
  "logtype-124": "No active campaign",
  "no_active_campaigns-desc": "You have no active campaigns on this store.",
  "logtype-122": "Data loss",
  "feed_import_drop-desc": "We detected a difference in volume in your products. Previously: {previous} products. Currently: {current} products.",
  "logtype-120": "Feed synchronized",
  "logtype-129": "No product!",
  "logtype-130": "Feed error",
  "logtype-131": "Merchant Center",
  "logtype-3": "Problem accessing advertising account",
  "logtype-121": "Product import",
  "logtype-132": "Campaign - No conversion",
  "logtype-132-desc": "This campaign has no conversion data.",
  "logtype-133": "Campaign - Low ROI",
  "logtype-133-desc": "This campaign has a low ROI.",
  "logtype-136": "No pixel tracking",
  "logtype-136-desc": "No pixel tracking is configured on your account.",
}

const ai = {
  "ai-generation": "AI Generation",
  "one-credit-used": "One credit used",
  "sub-ai": "Feedcast AI",
  "included-in-your-plan": "Included in your plan: {nb} credits",
  "unlimited-ai": "Unlimited AI",
  "unlimited": "UNLIMITED",
  "title-generation": "Title Generation",
  "gen-new-version": "Generate a new version",
  "credits-nb": "AI Credits: {nb}",
  "credits": "AI Credits: ",
  "ai-credits": "AI Credits",
  "monthly-recurring": "per month",
  "your-plan": "Your plan",
  "upgrade-to-unlimited": "Upgrade to unlimited AI",
}

const products = {
  "data-source": "Product Data",
  "channel-offline": "Channel offline",
  "title-and-desc": "Title and Description",
  "attributes": "Attributes",
  "ids-and-data": "IDs and Data",
  "product-information": "Information",
  "product-detail": "Product Detail",
  "product-weight": "Product Weight",
  "product-link": "Product Page Link",
  "channel-statuses": "Channel Statuses",
  "original-image-url": "Original Image",
  "image-url": "Image URL",
  "quick-edit": "Quick edit",
  "product-edit": "Product details",
  "see-product": "Product link",
  "title-or-ref": "Title or product reference",
  "hide-products-not-found": "Hide products no longer in my current catalog",
  "see-full-product": "Product details",
  "preview-product": "Preview",
  "product-online": "Product online",
  "pending-new-validation": "Pending new validation",
  "batch-pending-validation-desc": "This product has been edited since its last synchronisation with the selected channel.",
  "feature-in-dev": "Feature in development",
  "error-detected": "Product blocked",
  "channel-disabled": "Channel disabled",
  "warning-issues": "Online with warning(s)"
}

const dashboard = {
  "feedcast-ai": "Feedcast AI",
  "ai-hero-desc": "AI is taking an even bigger role in Feedcast! Test today how our specialized artificial intelligence can improve your product sheets by optimizing titles and descriptions.",
  "ai-cta": "Discover how to optimize my product sheets",
}

const campaigns = {
  "create-or-edit-a-label": "Create or edit a label",
  "all-locations": "All countries",
  "all-locations-desc": "Broadcast across all countries at once. No hard feelings!",
  "specific-locations": "Country selection",
  "specific-locations-desc": "Choose a specific geographical area.",
  "perf-max-photos": "Photos",
  "perf-max-photos-desc": "Choose which images you want to use for your campaign.",
  "perf-max-texts": "Campaign texts",
  "perf-max-texts-desc": "Provide the various texts that will be broadcast.",
  "perf-max-videos": "Videos",
  "perf-max-videos-desc": "You can add up to 5 videos to boost your campaign's broadcast!",
  "how-to-add-perf-max-video": "How to add videos?",
  "how-to-add-perf-max-video-desc": "We need your video's <b>identifier</b>. Your video's identifier is located at this spot in the URL: youtube.com/watch?v=<b>VIDEO_ID</b> {br} You can <b>copy and paste the entire URL</b> directly into the field, Feedcast will take care of removing the superfluous!",
  "roas-desc": "ROAS, or Return on Ad Spend in a Performance Max campaign, measures the profitability of the campaign by comparing the revenues generated to the advertising expenses. {br} It is a key indicator to assess the effectiveness of advertising expenditures in these automated and performance-oriented campaigns.",
  "perf-max-data": "Performance Max Data",
  "roas-long-txt": "Return on Ad Spend",
  "pictures-perf-max-recommendations": "Recommendation: {nb} images of {min} x {max} px",
  "logos": "Logo",
  "marketing_images_square": "Square",
  "marketing_images": "Landscape",
  "marketing_images_portrait": "Portrait (optional)",
  "perf-max-texts-best-practices": "How to write relevant texts?",
  "perf-max-texts-best-practices-desc": "To help you have the best possible campaigns, we've prepared a super guide on the best way to write your campaign texts!",
  "microsoft-shopping": "Microsoft Smart Shopping",
  "microsoft-shopping-desc": "Maximize your yield by using automated bidding and precise targeting on the Microsoft Search Network. Smart Shopping campaigns have priority over standard Shopping campaigns for the same targeted products",
  "campaign-desc": "Provide your campaign information",
  "locked-product-partition": "Locked product selection",
  "locked-product-partition-desc": "The segmentation of Microsoft campaigns cannot be changed once created. A correction will be made by Microsoft to allow you to modify your selection.",
  "delete-campaign": "Delete campaign",
  "delete-campaign-confirm": "This action will result in the permanent deletion of this campaign. If you wish to proceed, please write <b>campaign</b> in the field below.",
  "yes-delete-campaign": "Yes, delete permanently",
  "success-delete-campaign": "Your campaign has been deleted.",
  "delete-feed": "Delete store",
  "bidding-strategy": "Bidding strategy",
  "saving-campaign": "Your campaign is being saved...",
  "bidding-strategy-desc": "The bidding strategy determines how much you are willing to pay each time someone clicks on your ads. Here are the three strategic bidding options: <ul>" +
    "<li><b>Manual CPC bidding</b>: You directly control the maximum amount you are willing to pay for each click on your ads. </li>" +
    "<li><b>Maximize clicks</b>: Google automatically adjusts your bids to get as many clicks as possible with your set budget. </li>" +
    "<li> <b>Target ROAS</b>: You set a target return on advertising spend, and Google adjusts your bids to maximize your conversion value while achieving the ROAS you specified. (Disabled for new campaigns)</li></ul>",
  "ad-group-name": "Ad group name",
  "ad-group-name-desc": "The ad group brings your products together in a single category to simplify management and performance tracking.",
  "global-parameters": "Campaign",
  "global-parameters-desc": "Set up the essential elements of your campaign.",
  "targeting-parameters": "Which creative elements of your brand will we use?",
  "targeting-parameters-desc": "Optional",
  "add-countries": "Add a country",
  "meta-adv-benef": "The ad beneficiary refers to the party (usually a business or brand) that benefits from the advertising campaign, i.e., the one for whom the advertising is done.",
  "meta-adv-payor": "The ad payor is the person or entity that finances the campaign, paying for the advertising costs. These two roles can be assumed by the same entity or by different entities, depending on the situation.",
  "meta-adset": "The name of the adset group refers to the set of ads sharing the same targeting, budget, schedule, placement, and optimization settings",
  "meta-classic-platforms": "Broadcast channels",
  "all-that-have-not": "All those who do not",
  "all-that-have": "All those who have",
  "look-for-list-type": "Search for {listType}",
  "saving-campaign-data": "Saving campaign data...",
}

const configuration = {
  "google_ads-desc": "Ad account",
  "m_center-desc": "Product catalog, shipping fees",
  "meta_account-desc": "Ad account",
  "meta_pixel-desc": "Product catalog, Meta Pixel",
  "microsoft-desc": "Ad account",
  "microsoft-center-desc": "Product catalog",
  "pixels-detected": "Conversion pixels detected",
  "free_listing-desc": "Free listing of your product catalog on the Google Free Listing network",
  "disabled": "Disabled",
  "configured": "Configured",
  "service-to-configure": "Service to configure",
  "configuration": "Configuration",
  "begin-channel-config": "Start configuring your ad channel!",
  "no-tracking": "No tracking",
  "no-payment": "No payment method",
  "no-tracking-data": "No tracking detected",
  "no-tracking-data-desc": "You need to add a conversion tracking pixel to track the sales generated by your Google campaigns. Without this, you may face limitations in the reach and performance of your Google campaigns.",
  "no-channel-payment": "No payment method detected",
  "no-channel-payment-desc": "You have not added your payment method to your account. Please add your payment information to start running your ads.",
  "channel-active": "Channel active",
  "channel-disabled": "Channel disabled",
  "conversions-detected": "Conversion(s) detected",
  "no-conversions-detected": "No conversions detected",
  "pixel-list": "Pixel list",
  "tracking-issue": "No tracking",
  "concerned-channels": "Concerned channels: {channels}",
  "payment-issue": "No payment method",
  "important-notifications": "You have urgent notifications to check",
}


export default {
  ...en,
  ...pageInfo,
  ...projects,
  ...notifications,
  ...login,
  ...logtypes,
  ...ai,
  ...products,
  ...dashboard,
  ...campaigns,
  ...configuration,
};
