import React from "react";
import "flag-icon-css/css/flag-icons.min.css"

type LocaleProps = {
  locale: string,
  className?: string,
  style?: any,
};

const Locale: React.FC<LocaleProps> = ({ locale, className, style }) => {
  const parts = locale.split("_").map(p => p.toLowerCase()).reduce((prev: any, curr: string) => {
    if ("en" === curr) {
      curr = "gb";
    }

    if (-1 === prev.indexOf(curr)) {
      prev.push(curr);
    }

    return prev;
  }, []);

  return <span className={className} title={locale} style={style ?? {}}>
        <span className={`flag-icon flag-icon-${parts[0]}`}>
            {parts.length > 1 && <span key="country" className={`flag-icon flag-icon-${parts[1]}`}/>}
        </span>
    </span>
}

export default Locale;
