import React, { useEffect, useState } from "react";
import { GetCurrentFeedId } from "../../../../../../app/utils";
import productsApi from "../../../../../../app/services/secured/products";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  addProductVersion,
  getProductCustomData,
  getProductData, ProductAiField,
  setCustomData,
  setProductOriginal
} from "../../../../../../app/slices/product";
import { debounce } from "@mui/material";

export const useProductAi = () => {

  const productId = useAppSelector(state => state.product.product?.id) ?? 0;
  const productData = useAppSelector(getProductData);
  const customData = useAppSelector(getProductCustomData);

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();
  const [ aiField, setAiField ] = useState<null | "title" | "description">();

  const [ texts, setTexts ] = useState({
    title: "",
    description: "",
  });

  const [ productAi, productAiRes ] = productsApi.useProductAiMutation();

  // Main function to fetch API
  const generateAiText = (type: ProductAiField) => {
    if (productId) {
      setAiField(type);
      productAi({
        type,
        feed,
        feedProduct: productId,
      })
    }
  }

  // Initialise value with data from API
  useEffect(() => {
    if (productData) {
      setTexts({
        title: productData.title ?? "",
        description: productData.description ?? "",
      });

      dispatch(setProductOriginal({ field: "title", original: productData.title ?? "" }));
      dispatch(setProductOriginal({ field: "description", original: productData.description ?? "" }));
    }
  }, [ productData ]);

  // On each new generation, add to the slice
  useEffect(() => {
    if (productAiRes.isSuccess && aiField) {
      const resText = productAiRes.data.res;
      dispatch(
        addProductVersion({
          field: aiField,
          version: resText
        })
      );
    }
  }, [ productAiRes, aiField ]);

  // Function for manual (typed) edit of either title, or description
  const manualEdit = (value: string, type: ProductAiField) => {
    setTexts(t => ({ ...t, [type]: value }))
    debounceCustomData(type, value);
  }

  const debounceCustomData = debounce((type: ProductAiField, value: string) => {
    dispatch(setCustomData(({ ...customData, [type]: value })));
  }, 0);

  return {
    texts,
    manualEdit,
    productAiRes,
    generateAiText,
  }
}
