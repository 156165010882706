import AppPublic from "../AppPublic";
import FCard from "../../Blocks/Card";
import { FormattedMessage } from "react-intl";
import SimpleForm from "../../Blocks/SimpleForm";
import { LoadingButton } from "../../Blocks/Misc";
import authApi from "../../../../app/services/public/auth";
import img from "../../../../assets/img/validate-signup.svg";
import React, { useState } from "react";
import { Box, Fade, FormControl, InputAdornment, TextField, Typography } from "@mui/material";
import { CheckCircle, Email, RefreshOutlined } from "@mui/icons-material";
import { useAppSelector } from "../../../../app/hooks";
import { getAppMode } from "../../../../app/slices/app";

const Refresh = () => {
  const [ refresh, refreshRes ] = authApi.useRefreshMutation();
  const mode = useAppSelector(getAppMode);
  const [ showForm, setShowForm ] = useState(true); // State to manage form visibility
  const [ showSuccess, setShowSuccess ] = useState(false); // State to manage success message visibility

  // When form submission is successful, initiate hiding form
  if (refreshRes.isSuccess && showForm) {
    setShowForm(false);
  }

  return (
    <AppPublic page={"refresh"}>
      <Box>
        <Fade in={showForm} timeout={500} onExited={() => setShowSuccess(true)} unmountOnExit>
          <Box>
            <SimpleForm onSubmit={s => refresh(s as { email: string })}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  name={"email"}
                  label={'Email'}
                  size={"small"}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={"start"}>
                        <Email/>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
              <LoadingButton
                variant={"contained"}
                data={refreshRes}
                type="submit"
                endIcon={<RefreshOutlined/>}
                fullWidth
                color={mode === "dark" ? "secondary" : undefined}
              >
                <FormattedMessage id={'refresh-password'}/>
              </LoadingButton>
            </SimpleForm>
          </Box>
        </Fade>
        <Fade in={showSuccess} timeout={500} unmountOnExit>
          <Box sx={{display:"flex", alignItems:"center", gap: 1}}>
            <CheckCircle color={"success"} />
            <Typography>
              <FormattedMessage id={"refresh-password-success"}/>
            </Typography>
          </Box>
        </Fade>
      </Box>
    </AppPublic>
  )
}

export default Refresh;
