import React, { useEffect, useState } from "react";
import profileApi from "../../../../app/services/secured/profile";
import { StorageManager } from "../../../../app/storage";
import projectsApi from "../../../../app/services/secured/projects";
import { Navigate } from "react-router-dom";
import Urls from "../../../../assets/js/Urls";
import { Box } from "@mui/material";
import { LoadingDiv } from "../../Blocks/Misc";
import feedApi from "../../../../app/services/secured/feed";
import useJune from "../../../../app/hooks/useJune";
import { useAppSelector } from "../../../../app/hooks";
import { getUser } from "../../../../app/slices/app";
import { getLocaleDate } from "../../../../app/utils";

const Roundabout = () => {
  const isLogged = (StorageManager.get()?.length || 0) > 0;

  const meQuery = profileApi.useGetMeQuery();
  const getProjects = projectsApi.useGetProjectsQuery();
  const getFeeds = feedApi.useGetFeedsQuery();

  const [ hasLoaded, setHasLoaded ] = useState(false);

  useEffect(() => {
    if (meQuery.isSuccess && getProjects.isSuccess && getFeeds.isSuccess) {
      setHasLoaded(true);
    }
  }, [ meQuery.isSuccess, getProjects.isSuccess, getFeeds.isSuccess ]);

  const needsOnboarding = isLogged && (meQuery.data?.last_name?.length === 0 || getProjects.data?.length === 0 || getFeeds.data?.length === 0);
  const analytics = useJune();
  const userState = useAppSelector(getUser);
  useEffect(() => {
    if (analytics && userState && !userState.is_guest && getFeeds.isSuccess) {
      analytics.identify(userState.token, {
        email: userState.email,
        name: userState.first_name + " " + userState.last_name,
        created: getLocaleDate(new Date(userState.created * 1000).toDateString(), true),
        channels: userState.accounts,
        shops: getFeeds.data?.length,
      });
    }
  }, [ analytics, userState, getFeeds.isSuccess ]);

  return <React.Fragment>
    <Box
      height={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <LoadingDiv/>
    </Box>
    {hasLoaded && <Navigate to={
      needsOnboarding
        ? Urls.onboarding
        : Urls.projects.list
    }/>}
  </React.Fragment>
}

export default Roundabout;
