import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import React, { useEffect, useMemo, useState } from "react";
import { CircularProgress, debounce, FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { AutoAwesome, Refresh } from "@mui/icons-material";
import { AiFieldProps } from "./TitleDesc";
import { motion } from "framer-motion";
import { getCurrentProductAi, getProductData } from "../../../../../app/slices/product";

function getRandomRoundNumber() {
  return Math.round(Math.random() * (50 - 25 + 1)) + 25;
}

export const Title = ({ value, disabled, onChange, generateAiText, productAiRes }: AiFieldProps) => {

  const textAi = useAppSelector(getCurrentProductAi("title"));
  const defaultValueText = useAppSelector(getProductData).title;

  const [ controlText, setControlText ] = useState(value); // mainly for AI writing type
  const [ isWriting, setIsWriting ] = useState(false);
  const [ writingSpeed, setWritingSpeed ] = useState(35);

  const isOriginalBeingDisplayed = useMemo(() => controlText === textAi?.original, [ controlText, textAi?.original ]);

  const loading = productAiRes.isLoading;

  const v = textAi?.versions ?? [];
  const hasVersions = v.length > 0;
  const optimisedTitle = useMemo(() => v.length ? v[v.length - 1] : null, [ v.length ]);

  const debounceTitleChange = debounce((value: string) => {
    onChange(value);
  }, 500);

  const editText = (text: string) => {
    setControlText(text);
    debounceTitleChange(text);
  }

  const genAiText = (text: string) => {
    let index = 0;
    setIsWriting(true);
    let control = "";
    setTimeout(() => {
      const interval = setInterval(() => {
        if (index < text.length) {
          control += text[index];
          editText(control);
          index++;
          setWritingSpeed(getRandomRoundNumber());
        } else {
          clearInterval(interval);
          setIsWriting(false);
        }
      }, writingSpeed);
    }, 200);
  }

  useEffect(() => {
    if (defaultValueText?.length) {
      setControlText(defaultValueText);
    }
  }, []);

  useEffect(() => {
    if (optimisedTitle && !isWriting) {
      genAiText(optimisedTitle.content);
    }
  }, [ optimisedTitle ]);

  const toggleGenerateAndRevert = () => {
    !hasVersions
      ? generateAiText("title")
      : toggleVersions()
  }

  const toggleVersions = () => {
    if (textAi && !isOriginalBeingDisplayed) {
      editText(textAi.original);
      onChange(textAi.original);
    } else if (optimisedTitle?.content) {
      editText(optimisedTitle.content);
      onChange(optimisedTitle.content);
    }
  }

  return (
    <FormControl fullWidth>
      <TextField
        label={<FormattedMessage id={"title"}/>}
        value={controlText}
        disabled={disabled}
        onChange={(e) => editText(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <motion.div
                initial={{
                  opacity: 0.7,
                  transform: "scale(0.9)",
                }}
                whileHover={{
                  opacity: 1,
                  transform: "scale(1)",
                }}
              >
                <IconButton
                  disabled={loading}
                  onClick={toggleGenerateAndRevert}
                  sx={{
                    background: (theme) => theme.palette.gradient.secondary,
                  }}
                >
                  {!loading
                    ? !hasVersions || isOriginalBeingDisplayed
                      ? (<AutoAwesome/>) : (<Refresh/>)
                    : (<CircularProgress size={"1rem"} sx={{ color: '#fff' }}/>)}
                </IconButton>
              </motion.div>
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  )
}
