import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { addNotification } from "../slices/notifications";
import { StorageManager } from "../storage";
import { RootState } from "../store";
import { modalShowFeedcastAi, ModalTypes, openModal } from "../slices/modal";

export const httpErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status: string = (action.payload.originalStatus ?? action.payload.status).toString();

    switch (status) {
      case "400":
        api.dispatch(addNotification({
          type: "error",
          message: "invalid-request",
        }));
        break;

      case "401":
        StorageManager.setRedirectAfterLogin(window.location.href);
        StorageManager.clear();
        api.dispatch(addNotification({
          type: "error",
          message: "error-auth",
        }));
        if (action.meta.arg.endpointName !== 'login') {
          window.location.href = process.env.REACT_APP_APP_DOMAIN + "/";
        }
        break;
      case "403":
        api.dispatch(addNotification({
          type: "error",
          message: "unauthorized",
        }));
        break;

      case "404":
        api.dispatch(addNotification({
          type: "error",
          message: "unknown-resource",
        }));
        break;

      case '413':
        api.dispatch(addNotification({
          type: "error",
          message: "payload-too-large",
        }))
        break;

      case '429':
        api.dispatch(addNotification({
          type: "error",
          message: "limit-per-day",
        }))
        break;


      case '426':
        api.dispatch(openModal({
          type: ModalTypes.FeedcastAI,
        }));
        break;

      case "500":
        const state: RootState = api.getState();
        if (!state.modal.length || state.modal[0].type !== ModalTypes.AssetsManager) {
          api.dispatch(addNotification({
            type: "error",
            message: action.payload?.data?.error || "backend-error",
          }));
        }
        break;

      case "PARSING_ERROR":
        api.dispatch(addNotification({
          type: "error",
          message: "unknown-backend-error",
        }));
        break;

      case "login_error":
        api.dispatch(addNotification({
          type: "error",
          message: "bad-credentials",
        }));
        break;

      default:
        api.dispatch(addNotification({
          type: "error",
          message: `Error : (${status})`,
        }));
        break;
    }
  }

  return next(action);
}
