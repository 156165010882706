import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product, ProductData } from "../types/products";
import { AppThunk, RootState } from "../store";
import { FeedDataFeedcast } from "../utils";

type ProductSlice = {
  product: Product | null,
  productPreview: number | null, // Opens a preview of the selected product in a Drawer
  data: Partial<ProductData>,
  apiCustomData: Partial<ProductData> | null,
  source: DataSource,
  view: ProductEditView,
  ai: AiState,
}

const initialState: ProductSlice = {
  product: null,
  productPreview: null,
  data: {},
  apiCustomData: null,
  source: "feedcast",
  view: "page",
  ai: {
    title: { original: "", versions: [], chosen: null },
    description: { original: "", versions: [], chosen: null, }
  }
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductSlice: (state, action: PayloadAction<Product>) => {
      state.product = action.payload;
      state.data = FeedDataFeedcast(action.payload);
      state.apiCustomData = action.payload.custom_data;
    },
    toggleDataSource: (state) => {
      state.source = state.source === "feedcast" ? "origin" : "feedcast";
      if (state.product) {
        if (state.source === "origin") {
          state.data = state.product.data;
        } else {
          state.data = FeedDataFeedcast(state.product);
        }
      }

    },
    setCustomProductData: (state, action: PayloadAction<Partial<ProductData>>) => {
      if (state.product) {
        state.product.custom_data = action.payload;
      }
    },
    setProductView: (state, action: PayloadAction<"drawer" | "page">) => {
      state.view = action.payload;
    },
    setInitProduct: (state) => {
      state.product = initialState.product;
      state.ai = initialState.ai;
      state.view = initialState.view;
      state.data = initialState.data;
      state.source = initialState.source;
    },
    setProductPreview: (state, action: PayloadAction<number | null>) => {
      state.productPreview = action.payload;
    },

    // AI
    setProductOriginal: (state, action: PayloadAction<{ field: ProductAiField, original: string }>) => {
      const { field, original } = action.payload;
      state.ai[field].original = original;
    },
    addProductVersion: (state, action: PayloadAction<{ field: ProductAiField, version: string }>) => {
      const { field, version } = action.payload;
      const versions = state.ai[field].versions;
      versions.push({ id: Date.now().toString(), content: version });
    },
    chooseProductVersion: (state, action: PayloadAction<{ field: ProductAiField, id: string }>) => {
      const { field, id } = action.payload;
      state.ai[field].chosen = id === 'original' ? state.ai[field].original : state.ai[field].versions.find(v => v.id === id)?.content ?? null;
    },
    initProductVersion: (state, action: PayloadAction<{ field: ProductAiField }>) => {
      const { field } = action.payload;
      state.ai[field] = initialState.ai[field];
    },
    removeProductVersions: (state, action: PayloadAction<{ field: ProductAiField }>) => {
      const { field } = action.payload;
      state.ai[field].versions = [];
    }
  }
})

export const productReducer = productSlice.reducer;
export const {
  setProductSlice,
  toggleDataSource,
  setCustomProductData,
  setProductView,
  setInitProduct,
  setProductOriginal,
  addProductVersion,
  chooseProductVersion,
  initProductVersion,
  removeProductVersions,
  setProductPreview,
} = productSlice.actions;

//=========
// Getters
//=========

export const getProduct = (state: RootState) => state.product.product;
export const getProductData = (state: RootState) => state.product.data;
export const getProductDataSource = (state: RootState) => state.product.source;
export const getProductCustomData = (state: RootState): Partial<ProductData> => state.product.product?.custom_data ?? {};
export const getProductView = (state: RootState) => state.product.view;
export const getCurrentProductAi = (field: ProductAiField | undefined) => (state: RootState) => field ? state.product.ai[field] : null;
export const getProductIsLoading = (state: RootState) => state.product.product === null;
export const getProductPreview = (state: RootState) => state.product.productPreview;

export const productHasUnsavedChanges = (state: RootState) => {
  const product = state.product;
  if (product && product.product) {
    if (isObjectEmpty(product.apiCustomData) && !isObjectEmpty(product.product.custom_data)) {
      return true;
    }
    else if (product.apiCustomData !== null && product.product?.custom_data && !areEqual(product.product?.custom_data, product.apiCustomData)) {
      return true;
    }
  }
  return false;
}

function isObjectEmpty(obj: Object|null): boolean {
  if (obj === null) {
    return true;
  }
  
  const keysObj = Object.keys(obj);
  for (const key of keysObj) {
    // @ts-ignore
    if (obj[key as any].toString().length > 0) {
      return false;
    }
  }
  return true;
}

function areEqual<ProductData>(objA: Partial<ProductData>, objB: Partial<ProductData>): boolean {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (objA[key as keyof ProductData] !== objB[key as keyof ProductData]) {
      return false;
    }
  }

  return true;
}

//=========
// Setters
//=========

export const setCustomData = (c: Partial<ProductData>): AppThunk => dispatch => dispatch(setCustomProductData(c));

//=========
// Types
//=========
export type DataSource = "feedcast" | "origin";
export type ProductEditView = "drawer" | "page";

interface AiField {
  original: string,
  versions: AiVersion[],
  chosen: string | null,
}

interface AiState {
  title: AiField,
  description: AiField
}

export type AiVersion = { id: string, content: string };
export type ProductAiField = "title" | "description";

