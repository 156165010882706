import { determineIfDowngrade, useSubscriptionModal } from "./hooks/hooks";
import React, { useEffect, useState } from "react";
import { SubInterface } from "./Subscription";
import SimpleForm from "../../../App/Blocks/SimpleForm";
import { Backdrop, Box, CircularProgress, Stack, Typography } from "@mui/material";
import FCard from "../../../App/Blocks/Card";
import DialogCancel from "./Components/DialogCancel";
import { CancelData, SubscriptionDiscount } from "../../../../app/types/feed";
import { Shop2 } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { PlanDisplay } from "./Form/PlanDisplay";
import { ProductsDisplay } from "./Form/ProductsDisplay";
import { PayToday } from "./Form/PayToday";
import { PayNext } from "./Form/PayNext";
import { SubscriptionAlerts } from "./Form/SubscriptionAlerts";
import { CGV } from "./Form/CGV";
import { ConfirmSubscription } from "./Form/ConfirmSubscription";
import { CouponCode } from "./Form/CouponCode";
import { useHandleSubscription } from "./hooks/useHandleSubscription";
import { useSubscriptionData } from "./hooks/useSubscriptionData";
import { useSubscriptionManagement } from "./hooks/useSubscriptionManagement";
import { SubPlatforms } from "./Form/SubPlatforms";
import { Packs } from "./constants";
import SubAI from "./Form/SubAI";

export interface SubFormProps extends SubInterface {
  min: number | null | undefined,
  onLimitChange: (limitId: number) => void,
  onCodeChange: (promotionCode: string) => void,
  onPlatformChange: (platform: number) => void,
  onAiChange: (ai: number) => void,
  discounts?: SubscriptionDiscount[],
}

const SubForm = (props: SubFormProps) => {

  const { currSub, ...p } = props;

  const { open, handleClose, handleOpen } = useSubscriptionModal();

  const { currFeed, currSubStatus, selectedPackCode } = useSubscriptionData(currSub);
  const { selectedProducts, total, isCurrentSub } = useSubscriptionManagement(currSub, currSubStatus, selectedPackCode);

  const [ {
    submit, preview, toPayToday, toPayNext, dateNext, isLoading, isNew
  }, apiRes ] = useHandleSubscription({ currSub, selectedProducts });

  const isDowngrade = determineIfDowngrade(preview);
  const hasPromotion = (currSub.promotion_code?.length || 0) > 0;

  const [ showBackDrop, setShowBackdrop ] = useState<boolean>(false);
  const [ cancelFeedback, setCancelFeedback ] = useState<CancelData>();

  //Submit
  const handleSubmit = () => {
    if (!currSub.pack) {
      handleOpen()
    } else {
      submit(
        {
          callback: () => setShowBackdrop(true),
        }
      );
    }
  }

  // Cancel
  const confirmCancel = () => {
    handleClose();
    submit({
      cancelData: cancelFeedback,
      callback: () => setShowBackdrop(true),
    });
    setShowBackdrop(true);
  }

  useEffect(() => {
    if (apiRes.isError) {
      setShowBackdrop(false);
    }
  }, [ apiRes.isError ]);

  return (
    <Box>
      <Stack spacing={1}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography color={"text.secondary"} lineHeight={1}>
            <Shop2/>
          </Typography>
          <Typography color={"text.secondary"} lineHeight={1}>
            <FormattedMessage id={"your-subscription"}/>
          </Typography>
        </Stack>
        <FCard>
          <Stack width={'100%'} display={"flex"} spacing={3}>
            <PlanDisplay
              currSubStatus={currSubStatus}
              selectedPackCode={selectedPackCode}
            />
            <ProductsDisplay
              selectedPack={selectedPackCode}
              {...props}
            />
            <SubPlatforms
              currSub={currSub}
              selectedPack={selectedPackCode}
              onPlatformChange={p.onPlatformChange}
            />
            <SubAI
              currSub={currSub}
              selectedPack={selectedPackCode}
              onAiChange={p.onAiChange}
            />
            <CouponCode
              coupon={currSub.promotion_code}
              show={isNew}
              onCodeChange={p.onCodeChange}
              isAutopilot={selectedPackCode === Packs.Autopilot}
            />
            <PayToday
              show={toPayToday > 0 && currSub.pack > 0}
              toPayToday={toPayToday}
              isLoading={isLoading}
            />
            <PayNext
              total={total}
              packId={currSub.pack}
              discounts={p.discounts}
              dateNext={dateNext}
              toPayNext={toPayNext}
              isLoading={isLoading}
            />
          </Stack>
        </FCard>
        <SimpleForm onSubmit={handleSubmit}>
          <CGV/>
          <SubscriptionAlerts
            showCancel={currSub.pack === 0 && !isNew}
            showDowngrade={isDowngrade && currSub.pack > 0}
            showPromotion={hasPromotion}
          />
          <ConfirmSubscription
            apiRes={apiRes}
            isCurrentSub={!isDowngrade && isCurrentSub}
            currSubStatus={currSubStatus}
            selectedPack={selectedPackCode}
            hasPromotionCode={hasPromotion}
          />
        </SimpleForm>
      </Stack>

      {/** Background **/}
      <DialogCancel
        open={open}
        handleClose={() => handleClose()}
        setCancelFeedback={setCancelFeedback}
        submit={confirmCancel}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackDrop}
        onClick={handleClose}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
    </Box>
  )
}

export default SubForm;
