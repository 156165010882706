import { useProductAi } from "./AI/useProductAi";
import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Title } from "./Title";
import { Description } from "./Description";
import { ReduxRtkApiResult } from "../../../../../app/types";
import { useAppSelector } from "../../../../../app/hooks";
import { getProductDataSource, ProductAiField } from "../../../../../app/slices/product";

export interface AiFieldProps {
  onChange: (value: string) => void;
  value: string;
  disabled: boolean;
  generateAiText: (field: ProductAiField) => void;
  productAiRes: ReduxRtkApiResult,
}

export const TitleDesc = () => {

  const disableInputs = useAppSelector(getProductDataSource) === "origin";

  const {
    texts,
    manualEdit,
    productAiRes,
    generateAiText,
  } = useProductAi();

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Title
          value={texts.title}
          disabled={disableInputs}
          generateAiText={generateAiText}
          onChange={(val) => manualEdit(val, "title")}
          productAiRes={productAiRes}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Description
          value={texts.description}
          disabled={disableInputs}
          generateAiText={generateAiText}
          onChange={(val) => manualEdit(val, "description")}
          productAiRes={productAiRes}
        />
      </Grid2>
    </Grid2>
  )
}

